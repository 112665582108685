<template>
  <div
    class="card_container"
    :style="{
      background: active ? 'none' : getColor,
      transitionDelay: active ? '0s' : `${(processes.length + 1) * 0.2}s`,
      zIndex: active ? 2 : 1,
    }"
  >
  <div class="first_display" @click="handleActive">
    <div class="head">
      <tw-entity-icon :entityName="entityName" size="medium" color="white" />
      <tw-status-label :statusName="getStatusLabel" size="small" />
    </div>
    <div class="contract_no">
      <div style="flex:1;width: 100px">
        <label>{{getNumberLabel}}</label>
        <tw-overflow-tooltip :content="item.no" />
      </div>
      <div style="flex-shrink:0">
        <tw-subtask-icon :number="item.processStatus.length" />
      </div>
    </div>
  </div>
  <div
    class="first"
    :style="{
      transform: active
        ? `rotate3d(1, 0, 0, -180deg)`
        : `rotate3d(1, 0, 0, 0deg)`,
      transitionDelay: active ? '0s' : `${processes.length * 0.2}s`
    }"
  >
    <div class="first_top contract_no"
      v-if="processes.length"
      :style="{
        boxShadow: active ? '0px -4px 8px rgba(170, 170, 204, 0.5)' : 'none',
        transitionDelay: active ?  '0s' : `${(processes.length + 1) * 0.2}s`
      }"
      @click="handleActive"
    >
      <div style="flex:1;width: 100px">
        <label>{{getNumberLabel}}</label>
        <tw-overflow-tooltip :content="item.no" />
      </div>
      <div style="flex-shrink:0">
        <tw-subtask-icon :number="processes.length" />
      </div>
    </div>
    <TwFlowCardProcess v-if="processes.length" :active="active" :index="0" :processes="processes" :isDraft="isDraft" @show-detail="showDetail" />
  </div>
</div>

</template>

<script>
import _ from 'lodash';
import { ENTITY_PROGRESS_STATUS_VARIABLES, DETAIL_VIEWABLE_FLG, ENTITY_TYPE } from 'lib-tw-common';
import { entityNames } from '@/dictionaries/map.js';
import { color } from '@/assets/styles/styles.js';
import TwFlowCardProcess from '@/components/molecules/TwFlowCardProcess';
import TwEntityIcon from '@/components/atoms/TwEntityIcon.vue';
import TwSubtaskIcon from '@/components/atoms/TwSubtaskIcon.vue';
import TwStatusLabel from '@/components/atoms/TwStatusLabel';
import TwOverflowTooltip from '@/components/atoms/TwOverflowTooltip';

export default {
  name: 'TwFlowCardItem',
  components: {
    TwEntityIcon,
    TwSubtaskIcon,
    TwStatusLabel,
    TwFlowCardProcess,
    TwOverflowTooltip
  },
  props: {
    item: Object,
    invoiceNo: String,
    isDraft: Boolean,
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    entityName() {
      return _.get(_.find(entityNames, {code: this.item.entityTypeCd}), 'entityId');
    },
    getStatusLabel() {
      const label = _.get(_.find(ENTITY_PROGRESS_STATUS_VARIABLES, { code: this.item.entityProgressStatusCd }), 'label');
      return label ? _.snakeCase(label).toUpperCase() : '';
    },
    getNumberLabel() {
      if (this.item.entityTypeCd === ENTITY_TYPE.TRANSPORTATION) {
        return 'BL/AWB/SWB No';
      } else if (this.invoiceNo) {
        return 'Invoice No';
      } else {
        return 'Contract No';
      }
    },
    processes() {
      return this.item.processStatus;
    },
    getColor() {
      return color[`gradation_${this.entityName}`];
    }
  },
  mounted() {
    window.addEventListener('click', this.handleClick, false);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClick, false);
  },
  methods: {
    handleActive() {
      if (this.active) {
        this.active = false;
        return;
      }
      window.requestAnimationFrame(() => {
        this.active = true;
      });
    },
    showDetail(process) {
      if (process.detailViewableFlg === DETAIL_VIEWABLE_FLG.OFF) {
        return;
      }
      this.$emit('show-detail', {
        processId: process.processId,
        processSeq: process.processSeq,
        entityId: this.item.entityId,
      });
    },
    handleClick() {
      if (this.active) {
        this.active = false;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.card_container {
  position: relative;
  width: 175px;
  height: 82px;
  border-radius: 5px;
  transition: 0.2s;
  // margin: 20px;
  cursor: pointer;
  border: 1px solid rgba(0,0,0,0);
}

.first {
  width: 173px;
  height: 40px;
  top: 40px;
  position: absolute;
  color: #000;
  transform-origin: bottom;
  transform-style: preserve-3d;
  transition: 0.5s;
  border-radius: 4px;
  display: flex;
}

.first_display {
  width: 100%;
  height: 80px;
  position: absolute;
  background: #fff;
  border-radius: 4px;
  padding: 13px 12px 0;
  backface-visibility: hidden;
  @include card_shadow;
}

.head {
  display: flex;
  align-items: center;
  height: 27px;
  padding-bottom: 7px;

  .tw_entity_icon {
    margin-right: 4px;
  }

  .status_label {
    display: flex;
    align-items: center;
    height: 14px;
    border-radius: 7px;
    color: white;
    font-weight: bold;
    font-size: 10px;
    line-height: 1;
    padding: 0 8px 3px;
    background: #A0BBF0;
  }
}

.contract_no {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 1px 0 0;

  label {
    display: block;
    font-size: 10px;
    line-height: 14px;
    color: $color_gray_600;
    margin-bottom: 2px;
  }

  .el-tooltip {
    display: block;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: $color_gray_800;
    max-width: 115px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tw_subtask_icon {
    margin-top: 13px;
  }
}

.first_top {
  width: 173px;
  height: 40px;
  position: absolute;
  background: #ffffff;
  backface-visibility: hidden;
  border-radius: 4px;
  padding: 1px 12px 0;
  box-shadow: none;
  transition: box-shadow .2s;

  &.open {
    @include card_shadow;
  }
}
</style>
