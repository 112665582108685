// eslint-disable-next-line import/prefer-default-export
export const color = {
  // Brand Colors
  dark_blue: '#5185C5', // Main Blue
  primary_blue: '#3EC1FF', // Second Blue
  black: '#3E3A39',


  // Greyscale
  gray_800: '#5E5873',
  gray_600: '#9191A4',
  gray_400: '#BFBFCD',
  gray_300: '#EDEDF4',
  gray_100: '#F9F9FD',
  gray_background: '#FAFAFA',
  white: '#FFFFFF',

  // Warning
  warning: '#E00001',

  // Entity Gradient
  gradation_CONTRACT: 'linear-gradient(81.09deg, #7367F0 -29.88%, #DB8EFF 111.7%)',
  gradation_LC: 'linear-gradient(80.82deg, #0799A3 -17.87%, #29EEE2 101.59%)',
  gradation_TRANSPORTATION_AG: 'linear-gradient(120.45deg, #1C0898 6.47%, #516BF2 110.42%)',
  gradation_EX_CUSTOMS: 'linear-gradient(80.82deg, #4EB100 -17.87%, #C8E416 101.59%)',
  gradation_TRANSPORTATION: 'linear-gradient(236.52deg, #83F0FF 8.58%, #0766D6 105.71%)',
  gradation_MARINE_IP: 'linear-gradient(79.61deg, #EA5478 -14.57%, #FF98E2 104.44%)',
  gradation_FINAL_DOCS: 'linear-gradient(270deg, #FFD646 -11.21%, #FE7122 117.24%)',
  gradation_IM_CUSTOMS: 'linear-gradient(237.29deg, #FDCA66 5.38%, #FF1203 90.56%)',
  gradation_COMBINED: 'linear-gradient(135deg, #A60606 0%, #FF1313 100%)',

  // Label
  draft: '#FFC555',
  inprogress: '#6DD0C2',
  closed: '#E7E7E7',

  // Status
  done: '#00A960',
};
