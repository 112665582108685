<template>
  <div>
    <tw-header :title="$t('BreadCrumbs.Trading List')" :hasBottom="true">
      <template v-slot:column>
        <tw-button
          v-if="isShipper && isPic && !forbidden"
          type="secondary"
          size="small"
          icon="plus"
          style="margin-left: 24px"
          @click="addTrading"
        >
          Trading
        </tw-button>
      </template>
      <template v-slot:bottom>
        <div class="trading_tabs">
          <div class="tab list" :class="{active: activeTab === 0, disabled: !(isShipper && !forbidden)}" @click="changeTab(0)"><i/>List</div>
          <div class="tab flow" :class="{active: activeTab === 1, disabled: !(isShipper && !forbidden)}" @click="changeTab(1)"><i/>Flow</div>
        </div>
      </template>
    </tw-header>
    <div class="tw_container">
      <tw-breadcrumbs :pages="breadcrumbs" style="margin-bottom: 40px" />

      <el-form v-if="isShipper && !forbidden" class="search_unit" ref="searchUnit" :model="searchForm" label-position="top">
        <div class="row">
          <el-form-item :label="$t('Label.Owner')" prop="ownerSectionIds">
            <el-select v-model="searchForm.ownerSectionIds" class="section" :class="{multiple_selected: multipleSelect(searchForm.ownerSectionIds)}" placeholder="Select" multiple collapse-tags clearable>
              <el-option
                v-for="section in ownerSectionLists"
                :key="section.sectionId"
                :label="section.sectionShortName"
                :value="section.sectionId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Label.Trading Name')" prop="tradingName">
            <el-input type="text" v-model="searchForm.tradingName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <div>
            <tw-button
              type="secondary"
              size="medium"
              icon="search"
              @click="search"
            >
              Search
            </tw-button>
            <tw-button
              type="default"
              size="medium"
              @click="clear"
              style="margin-top: 23.5px;"
            >
              Clear
            </tw-button>
          </div>
        </div>
      </el-form>

      <div class="flow_list_container">
        <div class="trading_area">
          <div ref="tradingArea" class="infinite_scroller" v-infinite-scroll="fetch" infinite-scroll-disabled="disabled" infinite-scroll-distance="2">
            <div class="header">{{$t('Label.Trading')}}</div>
            <div class="trading" v-for="(trading, index) in tradings" :key="index" :class="{active: index == activeIndex, first: index === 0}">
              <div class="inner" @click="selectTrading(trading, index)">
                <el-tooltip placement="top-start" popper-class="mcu" :tabindex="-1">
                  <div slot="content">{{trading.tradingName}}</div>
                  <span class="trading_name">{{trading.tradingName}}</span>
                </el-tooltip>
                <span class="trading_id">{{trading.tradingId}}</span>
              </div>
            </div>
            <div class="loading_box">
              <img v-if="loading" class="loading" src="@/assets/images/loading.gif">
            </div>
          </div>
        </div>
        <div class="flow_area">
          <div class="header">{{$t('Label.Flow')}}</div>
          <template v-if="activeTrading">
            <div class="flow" v-for="(flow, index) in activeTrading.flows" :key="flow.tradingFlowId" :class="{ first: index === 0}">
              <div class="flow_label">
                <span class="main_sub" :class="[getFlowLabel(flow)]">{{getFlowLabel(flow)}}</span>
                <div
                  v-if="flow.flowDetailViewableFlg !== DETAIL_VIEWABLE_FLG.OFF"
                  class="flow_name"
                  @click="showFlowDetail(flow.tradingFlowId)"
                >
                  <tw-overflow-tooltip :content="flow.tradingManageName" />
                </div>
                <tw-overflow-tooltip
                  v-else
                  class="flow_name disabled"
                  :content="flow.tradingManageName"
                />
                <span class="flow_id">Flow ID: {{flow.tradingFlowId}}</span>
                <span class="tc_no">TC No: {{tcNo(flow)}}</span>
              </div>
              <div class="entities">
                <div class="entity" v-for="(entity) in entityNames" :key="flow.tradingFlowId + '_' + entity.code" :style="{width: `${entitySpanWidth}px`}">
                  <tw-flow-card-item v-if="getEntity(flow, entity.code)" :item="getEntity(flow, entity.code)" :invoiceNo="flow.invoiceNo" :class="[{hasEntity: nextEntityIndex(flow, entity.code, index)}, entity.entityId]" :isDraft="isDraft" @show-detail="showDetail" />
                  <template v-if="getEntity(flow, entity.code) && prevEntity(getEntity(flow, entity.code))">
                    <tw-flow-line v-for="(prev, i) in getEntity(flow, entity.code).prevEntityStatus || []" :key="i + '_' + prev.prevEntityId" :entity="getEntity(flow, entity.code)" :prevEntity="prev" :flowIndex="index" :flowHeight="flowHeight" :entitySpanWidth="entitySpanWidth" :cardWidth="cardWidth" :lineMargin="lineMargin" :partialInfo="activeTrading.flows" />
                  </template>
                </div>
              </div>
            </div>
          </template>
          <div v-else-if="showLoader" style="background: white;margin: 12px 0;padding: 0 40px;">
            <el-skeleton :rows="10" animated />
          </div>
        </div>
      </div>
    </div>
    <tw-drawer id="add_trading" :close-confirm="true" :class="[mode]" @close="closeConfirm" >
      <template slot>
        <tw-add-trading v-if="drawerShow && mode === 'new'" :history="prevData" />
      </template>
      <template slot>
        <tw-trading-detail-inner v-if="drawerShow && mode === 'detail'" :tradingId="activeTrading.tradingId" :tradingFlowId="selectFlowId" />
      </template>
    </tw-drawer>
  </div>
</template>

<script>
import _ from 'lodash';
import { API_USE_CODE, INITIAL_VIEW_FLG, DETAIL_VIEWABLE_FLG, TRADING_REGISTRATION_STATUS, SUBORDINATE_FLOW_FLG, VISIBLE_FLG } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import { entityNames } from '@/dictionaries/map.js';
import TwFlowLine from '@/components/atoms/TwFlowLine';
import TwFlowCardItem from '@/components/molecules/TwFlowCardItem';
import TwDrawer from '@/components/organisms/TwDrawer';
import TwAddTrading from '@/components/templates/TwAddTrading';
import TwTradingDetailInner from '@/components/templates/TwTradingDetailInner';
import schemas from '@/dictionaries/tradingListSchema.json';
import { clearSearchForm } from '@/utils/searchUtil.js';
import TwOverflowTooltip from '@/components/atoms/TwOverflowTooltip';

const TABS = [
  'ListTrading',
  'ListTradingFlow',
]
export default {
  name: 'TradingListFlow',
  mixins: [mixinEntityList],
  props: {
    history: Object,
  },
  components: {
    TwFlowCardItem,
    TwDrawer,
    TwAddTrading,
    TwTradingDetailInner,
    TwFlowLine,
    TwOverflowTooltip
  },
  data() {
    return {
      entitySpanWidth: 240, // エンティティ表示枠の幅
      cardWidth: 173, // エンティティカードの幅
      lineMargin: 13, // ラインのマージン
      flowHeight: 120, // フローの高さ
      activeTab: 1,
      schemas: schemas,
      items: [],
      addTradingResponse: null,
      searchForm: {
        ownerSectionIds: [],
        tradingName: '',
      },
      activeTrading: null,
      activeIndex: 0,
      mode: null,
      prevData: null,
      forbidden: false, // 認可エラーフラグ
      cache: {}, // フローキャッシュ
      showLoader: true,
      loading: false,
      selecting: false,
      isInitial: true,
      offset: 0,
      limit: 20,
      DETAIL_VIEWABLE_FLG,
      noMore: false,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
        {label: this.$t('BreadCrumbs.Trading List')},
      ];
    },
    ownerSectionLists() {
      return this.$store.getters.getOwnerSectionLists;
    },
    drawerShow() {
      return this.$store.state.drawerShow
    },
    tradings() {
      return _.map(_.groupBy(this.items, 'tradingId'), o => {
        return {
          tradingName: o[0].tradingName,
          tradingId: o[0].tradingId,
          flows: o,
        };
      });
    },
    entityNames() {
      return _.map(entityNames);
    },
    getEntity() {
      return (flow, entityTypeCd) => {
        return _.find(flow.entityStatus, {entityTypeCd: entityTypeCd});
      };
    },
    prevEntity() {
      return entity => {
        if (_.isEmpty(entity.prevEntityStatus)) {
          return false;
        }
        return entity.prevEntityStatus[0];
      };
    },
    nextEntityIndex() {
      return (flow, entityTypeCd, index) => {
        const nextIndex = _.findIndex(this.entityNames, (o, i) => {
          if (index >= i) {
            return false;
          }
          return _.find(flow.entityStatus, {entityTypeCd: this.entityNames[i].code});
        });
        if (nextIndex >= 0) {
          return nextIndex;
        } else {
          return false;
        }
      };
    },
    nextEntityPos(ref) {
      return ref;
    },
    isShipper() {
      // カンパニーロールが荷主か
      return this.$store.getters.isShipper;
    },
    isDraft() {
      // 選択した取引が下書き状態か
      return _.get(this.activeTrading, 'flows[0].tradingSubmitStatusCd') === TRADING_REGISTRATION_STATUS.SAVING;
    },
    disabled() {
      return this.loading || this.noMore;
    },
    tcNo() {
      return flow => {
        return _.get(flow, 'tradingChannelInfo.tradingChannelNo') || '';
      }
    },
    isPic () {
      return this.$store.state.userInformationV2?.picFlg;
    }
  },
  async created() {
    this.$store.commit('SET_TRADING_LIST_MODE', 1);
    if (this.history && this.isPic) {
      if (!_.isEmpty(this.history)) {
        this.prevData = this.history;
      }
      this.mode = 'new';
      this.$store.commit('SET_DRAWER', true);
    } else {
      this.$store.commit('SET_DRAWER', false);
    }

    const queries = this.$store.getters.getQueries('TRADING_LIST_FLOW');
    if (queries) {
      this.searchForm = queries;
    }

    this.fetch();

  },
  methods: {
    // POSTパラメータを生成します
    transformQueries() {
      const f = this.searchForm;
      const queries = {
        ownerSectionIds: f.ownerSectionIds,
        tradingName: f.tradingName,
        offset: this.offset,
        limit: this.limit,
      };

      return {
        ...queries,
        initialFlag: this.initialFlag(queries),
        apiUseCode: API_USE_CODE.TRADING_FLOW,
      };
    },
    fetch() {
      this.showLoader = true;
      this.loading = true;
      // bff_td_1 取引一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/tradings/search',
          query: this.transformQueries()
        },
        tag: this.pushCancelTag(),
      };

      $api.request(params)
      .then(res => {
        this.loading = false;
        if (this.offset === 0) {
          this.items = this.flatten(res.tradingFlowList, 'linkageInfo');
          this.activeIndex = 0;
          // スクロール位置をクリア
          if (_.get(this, '$refs.tradingArea.scrollTop')) {
            this.$refs.tradingArea.scrollTop = 0;
          }
        } else {
          this.items = [...this.items, ...this.flatten(res.tradingFlowList, 'linkageInfo')];
        }

        this.offset += res.count;
        this.noMore = !res.tradingFlowList.length || res.totalCount <= this.tradings.length;

        // this.activeTrading = this.tradings[0];
        if (!_.isEmpty(res.tradingFlowList) && _.get(this, 'tradings[0].tradingId') && this.isInitial) {
          this.fetchChildFlows(this.tradings[0].tradingId, this.tradings[0].tradingName);
          this.isInitial = false;
        } else {
          this.showLoader = false;
        }


        if (!_.isEmpty(res.ownerSectionLists)) {
          this.$store.commit('SET_OWNER_SECTION_LISTS', _.map(res.ownerSectionLists, s => {
            return {
              ...s,
              label: s.sectionShortName,
              value: s.sectionId,
            }
          }));
        }
      })
      .catch(err => {
        this.loading = false;
        if (err.isCanceled) {
          return;
        }
        if (err.statusCode === 403) {
          this.forbidden = true;
        }
        this.$store.dispatch('SHOW_ALERT', err.message);
        // this.items = [];
        // this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    search() {
      this.activeTrading = null;
      this.$store.commit('SET_QUERIES', {key: 'TRADING_LIST_FLOW', queries: _.cloneDeep(this.searchForm)});
      this.offset = 0;
      this.isInitial = true;
      this.noMore = false;
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.$store.commit('SET_QUERIES', {key: 'TRADING_LIST_FLOW', queries: _.cloneDeep(this.searchForm)});
    },
    // 新規取引追加ドロワー表示
    addTrading() {
      // if (!this.addTradingResponse) {
      //   this.addTradingResponse = addTradingResponse;
      // }
      this.mode = 'new';
      this.$store.commit('SET_DRAWER', true);
    },
    // タブ切り替え
    changeTab(tabIndex) {
      this.activeTab = tabIndex;
      this.$router.push({name: TABS[tabIndex]});
    },
    // ドロワーを閉じるときの確認
    closeConfirm() {
      if (this.mode === 'new') {
        this.$store.dispatch('SHOW_CONFIRM', 'Are you sure to go back? (Unsaved data shall be deleted)')
        .then(() => {
          this.$store.commit('SET_DRAWER', false);
          this.mode = null;
        })
        .catch(() => {});
      } else {
        this.$store.commit('SET_DRAWER', false);
        this.mode = null;
      }
    },
    // 指定したオブジェクトの中身を一階層上に展開
    flatten(ary, target) {
      return _.map(ary, o => {
        return {..._.omit(o, [target]), ...o.linkageInfo};
      });
    },
    showDetail(params) {
      this.$router.push({
        name: `Process${_.capitalize(params.processId.replace(/\d/g, ''))}`,
        params: { entityId: params.entityId, processSeq: params.processSeq, _processId: params.processId.toLowerCase() },
      });
    },
    selectTrading(trading, index) {
      if (this.activeIndex === index || this.selecting) {
        return;
      }
      this.activeIndex = index;
      this.fetchChildFlows(trading.tradingId, trading.tradingName);
    },
    showFlowDetail(flowId){
      this.selectFlowId = flowId;
      this.mode = 'detail';
      this.$store.commit('SET_DRAWER', true);
    },
    // 選択した取引のフローを取得します
    fetchChildFlows(tradingId, tradingName) {
      // キャッシュがあればキャッシュを表示
      if (this.cache[tradingId]) {
        this.activeTrading = this.cache[tradingId];
        return;
      }

      // キャッシュになければ取得する
      this.showLoader = true;
      this.selecting = true;
      this.activeTrading = null;
      // bff_td_1 取引一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/tradings/search',
          query: {
            tradingId: tradingId,
            initialFlag: INITIAL_VIEW_FLG.OFF,
            apiUseCode: API_USE_CODE.TRADING_FLOW,
          }
        },
        tag: this.pushCancelTag()
      };

      $api.request(params)
      .then(res => {
        const flows = this.flatten(res.tradingFlowList, 'linkageInfo');
        this.activeTrading = {
          tradingName: tradingName,
          tradingId: tradingId,
          flows: _.reject(flows, {visibleFlg: VISIBLE_FLG.OFF}),
        };
        // キャッシュに追加
        this.cache[tradingId] = this.activeTrading;
        this.showLoader = false;
        this.selecting = false;
      })
      .catch(err => {
        if (err.isCanceled) {
          return;
        }
        if (err.statusCode === 403) {
          this.forbidden = true;
        }
        this.showLoader = false;
        this.selecting = false;
        this.$store.dispatch('SHOW_ALERT', err.message);
        // this.items = [];
        // this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    // 取引管理のフローのメインかサブを返却します
    getFlowLabel(flow) {
      if (!flow.subordinateInfo) {
        return '';
      }
      if (flow.subordinateInfo.subFlg === SUBORDINATE_FLOW_FLG.ON) {
        return 'S';
      }
      if (flow.subordinateInfo.parentFlg === SUBORDINATE_FLOW_FLG.ON) {
        return 'M';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
  .tw_container {
      top: 113px;
  }
  .trading_tabs {
    display: flex;
    padding-top: 11px;
    padding-left: 3px;

    .tab {
      width: 70px;
      height: 34px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color_gray_800;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;

      &.active, &.disabled {
        pointer-events: none;
      }

      i {
        display: block;
        width: 24px;
        height: 24px;
        margin-right: 4px;
        background: no-repeat center;
        background-size: 20px auto;
      }

      &.list {
        margin-right: 51px;

        i {
          background-image: url(../assets/images/icons/icon_tab_list.svg);
        }

        &.active i {
          background-image: url(../assets/images/icons/icon_tab_active_list.svg);
        }
      }

      &.flow {
        margin-right: 47px;

        i {
          background-size: 24px auto;
          background-image: url(../assets/images/icons/icon_tab_flow.svg);
        }

        &.active i {
          background-image: url(../assets/images/icons/icon_tab_active_flow.svg);
        }
      }

      &.active {
        color: $color_dark_blue;
      }

      &.active:after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        border-radius: 4px 4px 0px 0px;
        background: $color_dark_blue;
        content: '';
      }
    }
  }

  ::v-deep #add_trading.side_drawer {
    width: auto;
    width: 1076px;

    > .inner {
      padding: 20px 44px 48px;
    }
  }

  .detail ::v-deep .side_drawer {
    width: auto;
  }

  .flow_list_container {
    position: absolute;
    top: 180px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: $color_white;

    .trading_area {
      position: absolute;
      top: 0;
      left: 0;
      width: 167px;
      height: 100%;
      background: linear-gradient(90deg, #5185C5 -1.68%, #3EC1FF 103.64%);
      flex-shrink: 0;
      overflow: hidden;
      // overflow-y: auto;
      padding-left: 11px;

      .header {
        position: sticky;
        width: 156px;
        top: 0;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color_white;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        padding-right: 11px;
        background: linear-gradient(90deg, #5185C5 -1.68%, #3EC1FF 103.64%);
        z-index: 2;
      }

      .trading {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .inner {
          width: 114px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: $color_white;
          margin-left: 15px;
          border-top: 1px solid rgba($color_gray_background, 0.2);
          cursor: pointer;

          .trading_name {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 4px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            max-height: 48px;
            -webkit-box-orient: vertical;
            // white-space: nowrap;
            // text-overflow: ellipsis;
            // overflow: hidden;
          }
          .trading_id {
            font-size: 12px;
            line-height: 18px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        &.active .inner {
          position: relative;
          width: 156px;
          height: 71px;
          border-radius: 12px 0 0 12px;
          background: $color_white;
          color: $color_gray_800;
          margin-left: 0;
          padding-left: 15px;
          padding-right:27px;
          border: none;
          box-shadow: inset 5px 4px 5px rgba(170, 170, 204, 0.5);
          z-index: 1;
        }

        &.active+div .inner {
          border: none;
        }

        &.first .inner {
          border: none;
        }
      }
    }

    .flow_area {
      position: absolute;
      top: 0;
      left: 167px;
      right: 0;
      height: 100%;
      overflow: auto;

      .header {
        position: sticky;
        top: 0;
        left: 0;
        height: 36px;
        display: flex;
        align-items: center;
        color: $color_gray_600;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        background: $color_white;
        padding-left: 45.5px;
        z-index: 3;
      }

      .flow {
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 26px;
        height: 121px;
        width: 1604px;

        .flow_label {
          position: relative;
          width: 190px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-right: 20px;
          border-top: 1px solid $color_gray_300;
          flex-shrink: 0;
          padding-left: 40px;

           span.main_sub {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 13px;
            height: 100%;
            text-align: center;
            font-weight: bold;
            font-size: 12px;
            line-height: 20px;
            color: white;

            &.M {
              background: #8AC7FF;
            }

            &.S {
              background: #DCEEFF;
              color: #70B7F8;
              left: 13px;
              margin-right: 0px;
            }
          }

          .flow_name {
            font-weight: bold;
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 2px;
            color: $color_dark_blue;
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &.disabled {
              color: $color_gray_600;
              cursor: not-allowed;
            }
          }
          .flow_id, .tc_no {
            font-size: 12px;
            line-height: 18px;
            color: $color_gray_600;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        &.first .flow_label {
          border: none;
        }

        .entities {
          display: flex;
          align-items: center;
          height: 110px;
          background: rgba(#F9F9FD, 0.6);
          @include soft_inner_shadow;
          border-radius: 6px;
          // min-width: calc(100% - 96px);
          padding: 0 25px 0 16px;
          flex-shrink: 0;

          .entity {
            position: relative;
            width: 240px;
            flex-shrink: 0;
            &:last-child {
              width: 173px!important;

              .flow_line {
                right: 178.5px!important;
              }
            }
          }

          // .flow_line {
          //   position: absolute;
          //   height: 8px;
          //   top: 38px;
          //   left: 181px;
          //   width: 54px;

          //   i {
          //     display: block;
          //     position: absolute;
          //     top: -2px;
          //     width: 12px;
          //     height: 12px;
          //     border-radius: 100%;

          //     &.start {
          //       left: -2px;
          //     }

          //     &.end {
          //       right: -2px;
          //     }
          //   }
          // }
        }
      }
    }
  }

  .infinite_scroller {
    scrollbar-color: rgba(0, 0, 0, 0) transparent;
    scrollbar-width: none;
    // transition: scrollbar-color .3s;
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;

    &::-webkit-scrollbar {
      position: absolute;
      width: 0;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      padding: 0 2px;
      // border-radius: 4px;
      // margin-top: 2px;
      // margin-bottom: 2px;
    }

    &::-webkit-scrollbar-thumb {
      cursor: pointer;
      border-radius: inherit;
      background-color: inherit;
      border-right: 2px solid transparent;
      border-left: 2px solid transparent;
      background-clip: padding-box;
      border-radius: 6px;
    }

    &:hover {
      scrollbar-width: thin;
      scrollbar-color: rgba(#aaa, .6) rgba(#eee, .9);

      // .header {
      //   padding-right: 0;
      // }

      // .trading.active .inner {
      //   padding-right: 0;
      // }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(#aaa, .6);
      }

      &::-webkit-scrollbar {
        width: 11px;
      }

      &::-webkit-scrollbar-track {
        background: rgba(#eee, .9);
        padding: 0;
      }
    }
  }

  .loading_box {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 11px;
    img.loading {
      display: block;
      width: 24px;
      height: 24px;
      margin: 0 auto 12px;
      filter: invert(100%);
      mix-blend-mode: screen;
      transform: translateZ(0);
    }
  }
</style>
