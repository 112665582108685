<template>
  <div class="trading_detail_inner">
    <header>
      <h1>Trading Details</h1>
    </header>
    <tw-trading-information :items="flowInfo || {}" :schema="flowInformationSchema" />
    <tw-button v-if="res" type="secondary" size="small" class="toggle_button" :class="{ open: expand }" @click="openToggle">
      <img src="@/assets/images/icons/view_all.svg">
    </tw-button>
    <!-- タブ -->
    <div v-if="res" ref="tabs" class="tabs" style="width: 100%">
      <div class="tab" v-if="schemaTab1.length" :class="{active: activeTab === 0}" @click="switchTab(0)">Goods</div>
      <div class="tab" v-if="schemaTab4.length" :class="{active: activeTab === 3}" @click="switchTab(3)">Information</div>
      <div class="tab" v-if="schemaTab2.length" :class="{active: activeTab === 1}" @click="switchTab(1)">Players</div>
      <div class="tab" v-if="schemaTab3.length" :class="{active: activeTab === 2}" @click="switchTab(2)">Logistics</div>
      <div class="tab" v-if="hasLcdata" :class="{active: activeTab === 5}" @click="switchTab(5)">L/C</div>
      <div class="tab" v-if="schemaTab5.length" :class="{active: activeTab === 4}" @click="switchTab(4)">Undisclosed</div>
    </div>
    <table class="card">
      <template v-if="res">
        <!-- タブコンテンツ -->
        <table v-show="activeTab === 0" class="card" style="position: relative;z-index:2">
          <tw-process-list v-for="table in schemaTab1" :table="table" :key="table.name" :tab="0" />
        </table>
        <table v-show="activeTab === 3" class="card" style="position: relative;z-index:2">
          <tw-process-list v-for="table in schemaTab4" :table="table" :key="table.name" :tab="3" />
        </table>
        <table v-show="activeTab === 1" class="card" style="position: relative;z-index:2">
          <tw-process-list v-for="table in schemaTab2" :table="table" :key="table.name" :visibleIp="false" :tab="1" />
        </table>
        <table v-show="activeTab === 2" class="card" style="position: relative;z-index:2">
          <!-- Vessel Name -->
          <tr v-if="vesselName" class="vessel_name">
            <td colspan="3">
              <tw-process-group-list-item
                :item="vesselName.item"
                :tableName="vesselName.tableName"
                :groupName="vesselName.groupName"
                :serialIndexes="{}"
                :serialItems="{}"
              />
              <div style="display: flex">
                <tw-process-group-list-item
                  :item="voyageNo.item"
                  :tableName="voyageNo.tableName"
                  :groupName="voyageNo.groupName"
                  :serialIndexes="{}"
                  :serialItems="{}"
                />
                <tw-process-group-list-item
                  :item="vesselBookingNo.item"
                  :tableName="vesselBookingNo.tableName"
                  :groupName="vesselBookingNo.groupName"
                  :serialIndexes="{}"
                  :serialItems="{}"
                />
              </div>
            </td>
          </tr>
          <tw-process-list v-for="table in schemaTab3" :table="table" :key="table.name" :tab="2" />
        </table>
        <table v-show="activeTab === 5" class="card" style="position: relative;border-radius: 0 0 6px 6px;z-index:2">
          <TwLcPoolProcessList v-if="hasLcdata" :initialOpen="false" :initSelectLc="false" :isProcess="true" :tab="5" />
        </table>
        <table v-show="activeTab === 4" class="card" style="position: relative;z-index:2">
          <tw-process-list v-for="table in schemaTab5" :table="table" :key="table.name" :tab="4" />
        </table>

        <!-- <tw-process-list v-for="table in schemas.tables" :table="table" :key="table.name" /> -->
      </template>
    </table>
    <!-- 添付ファイル -->
    <!-- <div class="attachment" v-if="res && res.tradingDocuments">
      <div class="file" v-for="(item, index) in res.tradingDocuments" :key="`${index}_${item.documentManageId}`">
        <div class="inner">
          <div class="user">
            <figure class="avatar table"><img :src="item.updateUserIcon"></figure>
            <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
              <div slot="content">{{item.updateUserName}}</div>
              <div class="from_name">{{item.updateUserName}}</div>
            </el-tooltip>
          </div>
          <a class="file_name" @click.prevent="download(item)" tabindex="-1">{{item.documentName}}</a>
          <span class="meta">{{item.documentSize | fileSize}},&nbsp;{{item.updateDate | dateTimeFormat}}</span>
        </div>
        <div class="comment" v-if="item.documentCommentText" v-html="sanitize(item.documentCommentText)" />
      </div>
    </div> -->
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { UNIT_ROUND_TYPE_VARIABLES, FREE_CHARGE_FLG_VARIABLES } from 'lib-tw-common';
import { formatQuantity, formatCurrency } from '@/utils/searchUtil.js';
import TwButton from '@/components/atoms/TwButton';
import TwProcessList from '@/components/organisms/TwProcessList';
import TwProcessGroupListItem from '@/components/molecules/TwProcessGroupListItem';
import TwLcPoolProcessList from '@/components/organisms/TwLcPoolProcessList';
import TwTradingInformation from '@/components/molecules/TwTradingInformation';
import schemas from '@/dictionaries/tradingDetailSchema.json';
import flowInformationSchema from '@/dictionaries/flowInformationSchema.json';
import { processTabType } from '@/dictionaries/processTabType.js';
import { computed } from "@vue/composition-api";
import { excludeDuplicatesIpData } from '@/utils/createIpDnData';

export default {
  name: 'TwTradingDetailInner',
  props: {
    tradingId: String,
    tradingFlowId: String,
  },
  provide() {
    return {
      s: this,
      tab: null,
      activeTab: computed(() => this.activeTab),
    }
  },
  components: {
    TwProcessList,
    TwTradingInformation,
    TwButton,
    TwProcessGroupListItem,
    TwLcPoolProcessList,
  },
  data() {
    return {
      schemas: schemas,
      flowInfo: null,
      res: null,
      flowInformationSchema: flowInformationSchema,
      expand: false,
      activeTab: 0,
      vesselName: null,
    };
  },
  computed: {
    SYS_MST_CURRENCY() {
      return this.$store.getters.getSysMstCurrency;
    },
    // 商品表示用配列を生成します
    goodsLineData() {
      if (!this.res.linkageGoodsLine) {
        return [];
      }
      return _.map(this.res.linkageGoodsLine, goods => {
        const price = _.find(this.res.linkageGoodsLinePrice, p => {
          return p.keyGrp.goodsLineSeq === goods.keyGrp.goodsLineSeq;
        });
        return {
          goodsId: _.get(goods, 'goodsGrp.goodsId') || null,
          goodsShortName: _.get(goods, 'goodsGrp.goodsShortName') || null,
          quantity1: formatQuantity(goods.quantityGrp.quantity1, goods.quantityGrp.unit1),
          unitRoundType1: _.get(_.find(UNIT_ROUND_TYPE_VARIABLES, {code: _.get(goods, 'quantityGrp.unitRoundType1')}), 'label') || null,
          price: `${formatCurrency(price.priceGrp.price, price.priceGrp.currency, this.getCurrencyMinorUnit(price.priceGrp.currencyId))}`,
          amount: formatCurrency(price.amountGrp.amount, price.priceGrp.currency, this.getCurrencyMinorUnit(price.priceGrp.currencyId)),
          quantity2: formatQuantity(goods.quantityGrp.quantity2, goods.quantityGrp.unit2),
          unitRoundType2: _.get(_.find(UNIT_ROUND_TYPE_VARIABLES, {code: _.get(goods, 'quantityGrp.unitRoundType2')}), 'label') || null,
          goodsRefNo1: _.get(goods, 'goodsGrp.goodsRefNo1') || null,
          goodsRefNo2: _.get(goods, 'goodsGrp.goodsRefNo2') || null,
          countryOfOriginName: _.get(goods, 'goodsGrp.countryOfOriginName') || null,
          noCommercialFlag:  _.get(_.find(FREE_CHARGE_FLG_VARIABLES, {code: _.get(price, 'commercialGrp.noCommercialFlag')}), 'label') || null,
          goodsLineId: goods.keyGrp.goodsLineId,
          goodsLineSeq: goods.keyGrp.goodsLineSeq,
        }
      })
    },
    // 梱包のテーブル表示用のデータを生成します
    goodsLinePackingItems() {
      if (!this.res.linkageGoodsLinePacking) {
        return [];
      }
      const ret = _.map(this.res.linkageGoodsLinePacking, packing => {
        const goods = _.find(this.res.linkageGoodsLine, g => {
          return g.keyGrp.goodsLineSeq === packing.keyGrp.goodsLineSeq;
        });
        return {
          goodsId: _.get(goods, 'goodsGrp.goodsId') || null,
          goodsShortName: _.get(goods, 'goodsGrp.goodsShortName') || null,
          netTotal: formatQuantity(packing.netGrossMeasurementGrp.netTotal),
          grossTotal: formatQuantity(packing.netGrossMeasurementGrp.grossTotal),
          weightUnit: packing.netGrossMeasurementGrp.weightUnit,
          numberOfContents: formatQuantity(packing.netGrossMeasurementGrp.numberOfContents),
          numberOfPackages: formatQuantity(packing.netGrossMeasurementGrp.numberOfPackages),
          packing: (_.get(packing, 'netGrossMeasurementGrp.packing') || '').split(',')[0], // カンマの前を表示
          netLength: formatQuantity(packing.netGrossMeasurementGrp.netLength),
          netWidth: formatQuantity(packing.netGrossMeasurementGrp.netWidth),
          netHeight: formatQuantity(packing.netGrossMeasurementGrp.netHeight),
          netMeasurementTotal: formatQuantity(packing.netGrossMeasurementGrp.netMeasurementTotal, '', 6),
          grossLength: formatQuantity(packing.netGrossMeasurementGrp.grossLength),
          grossWidth: formatQuantity(packing.netGrossMeasurementGrp.grossWidth),
          grossHeight: formatQuantity(packing.netGrossMeasurementGrp.grossHeight),
          grossMeasurementTotal: formatQuantity(packing.netGrossMeasurementGrp.grossMeasurementTotal, '', 6),
          lwhUnit: packing.netGrossMeasurementGrp.lwhUnit,
          measurementUnit: packing.netGrossMeasurementGrp.measurementUnit,
          goodsLineId: packing.keyGrp.goodsLineId,
          goodsLineSeq: packing.keyGrp.goodsLineSeq,
          packingLineSeq: packing.keyGrp.packingLineSeq,
          packingSublineSeq: packing.keyGrp.packingSublineSeq,
        }
      });

      // 商品梱包をgoodsLine順に並べ替えます
      const goodsLineIndexes = _.map(this.res.linkageGoodsLine, goods => {
        return _.get(goods, 'keyGrp.goodsLineSeq');
      });
      return _.sortBy(ret, (packing) => {
        const index = _.indexOf(goodsLineIndexes, packing.goodsLineSeq);
        return index === -1 ? undefined : index;
      });
    },
    // I/P情報表示用のデータを生成します（プロセス詳細）
    ipPreviewItems() {
      if (!_.get(this.res, 'linkageMarineInsurance.ippools')) {
        return [];
      }
      // キー項目が重複するI/Pは除外する
      const ret = excludeDuplicatesIpData(_.map(this.res.linkageMarineInsurance.ippools, pool => {
        return {
          docType: 'I/P',
          senderCompanyId: pool.policyGrp.senderCompanyId,
          ipRegistType: pool.ipRegistType,
          ipDnType: pool.policyGrp.ipDnType,
          ipDnTypeDn: _.get(pool, 'policyGrp.ipDnTypeDn'),
          shipperReferenceNo: pool.policyGrp.shipperReferenceNo,
          opOcProvNo: pool.policyGrp.opOcProvNo,
          insurancePolicyNo: pool.policyGrp.insurancePolicyNo,
          insurancePolicyBranchNo: pool.policyGrp.insurancePolicyBranchNo,
          invoiceNo: pool.invoiceGrp.invoiceNo,
          assuredsEtc: pool.assuredGrp.assuredsEtc
        }
      }));
      return ret;
    },
    goodsLineCoPreviewItems() {
      if (!this.res.linkageGoodsLineCo) {
        return [];
      }
      const ret = _.map(this.res.linkageGoodsLineCo, goods => {
        const goodsLine = _.find(this.res.linkageGoodsLine, g => {
          return g.keyGrp.goodsLineSeq === goods.keyGrp.goodsLineSeq;
        });
        return {
          goodsLineSeq: goods.keyGrp.goodsLineSeq,
          goodsId: goods.goodsGrp.goodsId,
          goodsName: goodsLine.goodsGrp.goodsShortName,
          countryOfOriginName: goods.goodsGrp.countryOfOriginName,
          quantity1: formatQuantity(goods.quantityGrp.quantity1, goods.quantityGrp.unit1),
          coGoodsName: goods.goodsGrp.goodsShortName,
          coApplicationNo: goods.keyGrp.coApplicationNo || '',
          remarks1: goods.remarksGrp.remarks1,
        }
      });

      return ret;
    },
    // Goodsタブの中身
    schemaTab1() {
      let goods = _.filter(this.schemas.tables, table => {
        return table.variableName.includes('GoodsLine');
      });
      const ORDER = [
        'linkageGoodsLinePrice',
        'linkageGoodsLine',
        'linkageGoodsLineType',
        'linkageGoodsLineCo',
        'linkageGoodsLinePacking',
      ];

      goods = _.orderBy(goods, [o => {return _.findIndex(ORDER, name => name === o.variableName)}], ['asc']);

      // goodsタブに他のテーブルの該当項目を追加
      let schemas = _.cloneDeep(this.schemas);
      _.forEach(schemas.tables, table => {
        table.groups = _.filter(table.groups, group => {
          return processTabType.goods.allGroup.includes(group.variableName);
        });
        if (table.groups && table.groups.length > 0) goods.push(table)
      });
      return goods;
    },
    // Players情報タブの中身
    schemaTab2() {
      return this.groupFilter('players');
    },
    // Logistics情報タブの中身
    schemaTab3() {
      return this.groupFilter('logistics');
    },
    // 共通情報タブの中身
    schemaTab4() {
      return this.groupFilter('information');
    },
    // エンティティ個別タブの中身
    schemaTab5() {
      return _.filter(this.schemas.tables, table => {
        return table.variableName.includes('Separate') || table.variableName.includes('processSeparate');
      });
    },
    // L/Cタブの表示
    hasLcdata() {
      const lcdata = _.get(this, 'res.linkageLc.lcpools[0].lcdata');
      return lcdata && Object.keys(lcdata).length > 0;
    },
    // 添付帳票コメント用
    sanitize() {
      return string => {
        return this.$sanitize(string).replace(/\n/g, '<br>');
      }
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    cloneDeep(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    fetch() {
      // bff_td_12 取引詳細表示BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/trading-detail/{tradingId}/{tradingFlowId}',
          path: {
            tradingId: this.tradingId,
            tradingFlowId: this.tradingFlowId,
          }
        }
      };

      $api.request(params)
      .then(res => {
        this.flowInfo = res.flowInfo;
        this.res = res;
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    getCurrency(currencyId) {
      return _.get(_.find(this.SYS_MST_CURRENCY, {alphabeticCode: currencyId}), 'symbol');
    },
    getCurrencyMinorUnit(currencyId) {
      return _.get(_.find(this.SYS_MST_CURRENCY, {alphabeticCode: currencyId}), 'minorUnit');
    },
    openToggle() {
      this.expand = !this.expand;
      if (this.expand) this.$emit('openAll');
      else this.$emit('closeAll');
    },
    // 依頼区分をチェックします NOTE: 取引詳細ではtypeOfWorksのチェックは行わない（常にtrue）
    checkTypeOfWorks() {
      return true;
    },
    // Player, Logistics, informationタブの項目フィルタリング処理
    groupFilter(type) {
      let schemas = _.cloneDeep(this.schemas);
      let tables = _.forEach(schemas.tables, table => {
        table.groups = _.filter(table.groups, group => {
          // Vessel NameをLogisticsタブのいちばん上に移動
          if (type === 'logistics' && group.variableName === 'logisticsLoadingGrp') {
            this.vesselName = {
              item: _.cloneDeep(_.find(group.children, {key: 'nameOfVessel'})),
              tableName: table.variableName,
              groupName: group.variableName,
            };
            this.voyageNo = {
              item: _.cloneDeep(_.find(group.children, {key: 'voyageNo'})),
              tableName: table.variableName,
              groupName: group.variableName,
            };
            group.children = _.reject(group.children, item => {
              return item.key === 'nameOfVessel' || item.key === 'voyageNo';
            });
          }
          if (type === 'logistics' && group.variableName === 'containerGrp') {
            this.vesselBookingNo = {
              item: _.cloneDeep(_.find(group.children, {key: 'vesselBookingNo'})),
              tableName: table.variableName,
              groupName: group.variableName,
            };
            group.children = _.reject(group.children, item => {
              return item.key === 'vesselBookingNo';
            });
          }

          if (type === 'information') {
            // informationタブは、players, logisticsタブにないものを表示する
            return !(processTabType.players.includes(group.variableName) || processTabType.logistics.includes(group.variableName));
          } else {
            return processTabType[type].includes(group.variableName);
          }
        });
      });
      return this.groupReject(tables);
    },
    // Player, Logistics, informationタブの項目リジェクト処理
    groupReject(tables) {
      return _.reject(tables, table => {
        // goodsタブに表示するグループを除外
        table.groups = _.reject(table.groups, group => {
          return processTabType.goods.allGroup.includes(group.variableName);
        });
        // エンティティ個別タブに表示するテーブルと、groupsが空のテーブルを除外
        return table.variableName.includes('Separate') || table.variableName.includes('GoodsLine') || table.variableName.includes('processSeparate') || table.groups.length === 0;
      });
    },
    // タブ切り替え
    switchTab(index) {
      this.activeTab = index;
    },
    // 添付帳票ダウンロード
    download(doc) {
      const path = {
        documentManageId: doc.documentManageId,
        documentTrx: doc.documentTrx
      };

      $api
      .download(path, doc.documentName)
      .then(() => {
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .trading_detail_inner {
    width: 988px;

    header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0 0 12px;
      border-bottom: 1px solid $color_gray_300;

      h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        margin: 0;
      }
    }

    table.card {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      box-shadow: 0;
      overflow: hidden;
      // margin-bottom: 20px;
      border-radius: 0 0 6px 6px;
    }

    ::v-deep .swiper-container {
      margin-bottom: 24px;
      background: $color_gray_100;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .attachment {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    .file {
      position: relative;
      margin-top: 16px;
      border-radius: 6px;
      padding: 12px 16px;
      background: $color_gray_100;
      &:first-child {
        margin-top: 32px;
      }
      .inner {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .user {
        max-width: 200px;
        margin-right: 24px;
        display: flex;
        align-items: center;
        color: $color_black;
        .from_name {
          white-space:nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .close_times {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 50%;
        right: 16px;
        margin-top: -12px;
        cursor: pointer;
      }
    }
    .meta {
      flex-shrink: 0;
    }
    a.file_name {
      // max-width: 710px;
      color: $color_dark_blue;
      margin-right: 16px;
      cursor: pointer;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .comment {
      margin-top: 12px;
      font-size: 12px;
      line-height: 18px;
      color: $color_gray_800;
    }
  }
</style>
