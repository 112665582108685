<template>
  <div v-if="visible" class="tw_process_group_list" :class="{open: open}">
    <div class="inner" @click="toggle">
      <img class="arrow" src="@/assets/images/icons/accordion_arrow.svg">
      <div class="group_name">{{group.name}}</div>
    </div>
    <transition
      name="content"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <div v-show="open" class="collapse">
        <template>
          <div class="twoColumn" style="margin-top:0">
            <template v-for="item in group.children">
              <tw-process-group-list-item
                :key="item.key"
                :item="getItem(item)"
                tableName="linkageLc"
                groupName="lcdata"
                poolName="lcpools"
                :poolIndex="selectIndex"
                :serialIndexes="{}"
                :serialItems="{}"
                :isEditMode="isEditMode"
              />
            </template>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash';
import TwProcessGroupListItem from '@/components/molecules/TwProcessGroupListItem';
import { collapseTransition } from '@/utils/nextFrame';

export default {
  name: 'TwProcessGroupListPreviewLc',
  inject: ['s'],
  props: {
    group: Object,
    // tableName: String,
    isEditMode: Boolean,
    selectIndex: Number,
    initOpen: {
      type: Boolean,
      default: false,
    },
    isUpload: Boolean,
    isProcess: Boolean,
  },
  components: {
    TwProcessGroupListItem,
  },
  mixins: [collapseTransition],
  data() {
    return {
      open: this.isEditMode || this.initOpen,
    };
  },
  computed: {
    visible() {
      const groupName = this.group.variableName;
      if (this.isUpload) {
        return groupName !== 'informationItems';
      } else if (this.isProcess) {
        return groupName !== 'informationItems' || groupName !== 'documentItems';
      }
      return true;
    },
  },
  created() {
    this.$parent.$on('openAll', this.openAll);
    this.$parent.$on('closeAll', this.closeAll);
  },
  beforeDestroy() {
    this.$parent.$off('openAll', this.openAll);
    this.$parent.$off('closeAll', this.closeAll);
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    openAll() {
      this.open = true;
    },
    closeAll() {
      this.open = false;
    },
    getItem(item) {
      if (item.key === 'requestedConfirmationParty') {
        // 確認銀行A or D
        const targetRes = this.isEditMode ? this.s.cloneItems : this.s.res;
        const requestedConfirmationPartyOptionA = _.get(targetRes, `${this.tableName}.lcpools[${this.selectIndex}].lcdata.requestedConfirmationPartyOptionA`) || _.get(targetRes, `${this.tableName}.lcpools[${this.selectIndex}].lcmeta.requestedConfirmationPartyOptionA`);
        if (requestedConfirmationPartyOptionA) {
          item.key = 'requestedConfirmationPartyOptionA';
        } else {
          item.key = 'requestedConfirmationPartyOptionD';
        }
        return item;
      } else return item;
    }
  },
};
</script>

<style lang="scss" scoped>
  .tw_process_group_list {
    margin-bottom: 16px;
    .inner {
      display: flex;
      align-items: center;
      padding: 10px 18px 10px 0;
      cursor: pointer;
      width: 100%;
      height: auto;

      img {
        display: block;
        width: 12px;
        height: 12px;
        transition: transform .3s;
      }

      .group_name {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        margin-left: 8px;
      }

      .tw_button {
        width: 190px;
        padding-right: 0;
        padding-left: 0;
        margin-left: auto;
      }
    }

    .collapse {
      border-top: 1px solid #EDEDF4;
      padding: 12px 0 10px;
      width: 100%;
    }

    &.open {
      .inner img.arrow {
        transform: rotate(90deg);
      }
    }
  }

  .content-enter-active,
  .content-leave-active {
    transition: height .3s;
  }

  .twoColumn {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    padding-left: 16px;

    .edit_document_payment_terms_hr {
      display: block;
      width: 100%;
      height: 1px;
      background: $color_gray_300;
      margin: 0 0 24px;
      border: 0;
    }

    .document_payment_terms_hr {
      display: block;
      width: 100%;
      height: 1px;
      background: $color_gray_300;
      margin: 0 0 12px;
      border: 0;
    }
  }
</style>
