<template>
  <div class="tw_process_group_list" :class="{open: open}">
    <div class="inner" @click="toggle">
      <img class="arrow" src="@/assets/images/icons/accordion_arrow.svg">
      <div class="group_name">L/C Details</div>
    </div>
    <transition
      name="content"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <div v-show="open" class="collapse">
        <template>
          <div class="twoColumn" style="margin-top:0">
            <template v-for="(items, index) in swiftItems">
              <div v-show="checkValues(items)" :key="index" class="tw_process_group_list_item">
                <div v-for="(item) in items" :key="item.key" class="value">{{item.value}}</div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash';
import { collapseTransition } from '@/utils/nextFrame';

export default {
  name: 'TwProcessGroupListPreviewLcSwift',
  props: {
    swiftItems: Array,
    initOpen: Boolean
  },
  mixins: [collapseTransition],
  data() {
    return {
      open: this.initOpen,
    };
  },
  computed: {},
  created() {
    this.$parent.$on('openAll', this.openAll);
    this.$parent.$on('closeAll', this.closeAll);
  },
  beforeDestroy() {
    this.$parent.$off('openAll', this.openAll);
    this.$parent.$off('closeAll', this.closeAll);
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    openAll() {
      this.open = true;
    },
    closeAll() {
      this.open = false;
    },
    // swift項目の値チェック
    checkValues(items) {
      // TabNo, TagName, TagValue全てに値がなければ非表示
      return _.some(items, (item) => {
        const value = item.value;
        return (value || value === 0);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .tw_process_group_list {
    margin-bottom: 16px;
    .tw_process_group_list_item {
      width: 100%;
      min-width: 0;
      display: flex;
      margin-bottom: 16px;
      .value {
        width: 7%;
        color: $color_gray_600;
        font-size: 12px;
        flex: auto;
        padding-right: 16px;
      }
      .value:nth-child(2) {
        width: 23%;
      }
      .value:nth-child(3) {
        width: 70%;
        color: $color_black;
        font-size: 14px;
      }
    }

    .inner {
      display: flex;
      align-items: center;
      padding: 10px 18px 10px 0;
      cursor: pointer;
      width: 100%;
      height: auto;

      img {
        display: block;
        width: 12px;
        height: 12px;
        transition: transform .3s;
      }

      .group_name {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        margin-left: 8px;
      }

      .tw_button {
        width: 190px;
        padding-right: 0;
        padding-left: 0;
        margin-left: auto;
      }
    }

    .collapse {
      border-top: 1px solid #EDEDF4;
      padding: 12px 0 10px;
      width: 100%;
    }

    &.open {
      .inner img.arrow {
        transform: rotate(90deg);
      }
    }
  }

  .content-enter-active,
  .content-leave-active {
    transition: height .3s;
  }

  .twoColumn {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    padding-left: 16px;
  }
</style>
