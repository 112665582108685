<template>
  <div class="first-behind">
    <div class="first-behind-display" :class="{disabled: isDraft || processes[index].detailViewableFlg === DETAIL_VIEWABLE_FLG.OFF}" @click.stop.prevent="onClick">
      <tw-status-icon v-if="typeof processes[index].processProgressStatusCd === 'number'" :statusCode="processes[index].processProgressStatusCd" :approval="isApproval" />
      {{getProcessShortName}}
    </div>
    <div
      class="second"
      :style="{
        transform: active
          ? `rotate3d(1, 0, 0, -180deg)`
          : `rotate3d(1, 0, 0, 0deg)`,
        transitionDelay: active ? `${(index + 1) * 0.2 + 0.1}s` : `${processes.length * 0.2 - (index + 1) * 0.2}s`
      }"
    >
      <div v-if="processes.length - 1 > index" class="second_top">
        <div class="inner" :style="{background: `rgba(255, 255, 255, ${1 - (index + 1) / processes.length})`}" />
      </div>
      <TwFlowCardProcess v-if="processes.length - 1 > index" :active="active" :index="index + 1" :processes="processes" :isDraft="isDraft" @show-detail="showDetail" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { processNames } from '@/dictionaries/map.js';
import { DETAIL_VIEWABLE_FLG } from 'lib-tw-common';
import TwStatusIcon from '@/components/atoms/TwStatusIcon';
import TwFlowCardProcess from '@/components/molecules/TwFlowCardProcess';
import { getProcessNumber } from '@/utils/entity';

export default {
  name: 'TwFlowCardProcess',
  components: {
    TwStatusIcon,
    TwFlowCardProcess
  },
  props: {
    active: Boolean,
    index: Number,
    processes: Array,
    isDraft: Boolean,
  },
  data() {
    return {
      DETAIL_VIEWABLE_FLG,
    }
  },
  computed: {
    getProcessShortName() {
      const processId = this.processes[this.index].processId;
      if (processId) {
        return _.get(processNames[processId.replace(/\d/g, '')], 'nameShort') + getProcessNumber(processId);
      } else {
        return '';
      }
    },
    isApproval() {
      const processId = this.processes[this.index].processId;
      if (processId) {
        return _.get(processNames[processId.replace(/\d/g, '')], 'approval');
      } else {
        return '';
      }
    }
  },
  methods: {
    onClick() {
      if (this.isDraft || this.processes[this.index].detailViewableFlg === DETAIL_VIEWABLE_FLG.OFF) {
        return;
      }
      this.$emit('show-detail', this.processes[this.index]);
    },
    showDetail(process) {
      this.$emit('show-detail', process);
    }
  }
};
</script>

<style lang="scss" scoped>
.first-behind {
  width: 173px;
  height: 40px;
  position: absolute;
  background: #fff;
  transform-origin: center;
  transform: rotate3d(1, 0, 0, -180deg);
  backface-visibility: hidden;
  border-radius: 4px;
  border: 1px dashed $color_gray_400;
  border-left: none;
  border-right: none;
}

.first-behind-display {
  width: 100%;
  height: 40px;
  position: absolute;
  background: #fff;
  border-radius: 4px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  @include card_shadow;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: $color_gray_800;

  &.disabled {
    cursor: not-allowed;
  }

  .tw_status_icon {
    display: block;
    flex-shrink: 0;
    margin-right: 4px;

    i {
      width: 12px;
      height: 12px;
    }
  }
}

.second {
  width: 173px;
  height: 40px;
  position: absolute;
  bottom: -2px;
  transform-origin: bottom;
  transform-style: preserve-3d;
  transition: 0.2s;
  border-radius: 4px;
}

.second_top {
  width: 173px;
  height: 40px;
  position: absolute;
  background: #d4d4e5;
  backface-visibility: hidden;
  border-radius: 4px;

  .inner {
    width: 100%;
    height: 100%;
  }
}
</style>
