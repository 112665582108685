<template>
  <div class="add_trading">
    <header>
      <h1>{{$t('Label.New Trading')}}</h1>
    </header>
    <section style="padding-bottom: 6px">
      <el-form :model="queries" :rules="rules" ref="formOwnerSectionId">
        <el-form-item
            :label="$t('Label.Owner')"
            prop="ownerSectionId"
          >
          <el-select
            v-model="queries.ownerSectionId"
            filterable
          >
            <el-option
              v-for="owner in searchCriteriaOwnerSections"
              :key="owner.sectionId"
              :label="owner.sectionShortName"
              :value="owner.sectionId"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </section>
    <section>
      <h2 style="margin-bottom: 0;cursor: pointer" @click="openToggle"><img class="arrow" src="@/assets/images/icons/accordion_arrow.svg" :class="{open: openForm}">{{$t('Label.Filter')}}</h2>
      <transition
          name="content"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
          @after-leave="afterLeave"
        >
        <div v-show="openForm">
          <table>
            <tr>
              <th>{{$t('Label.Trading Type')}}</th>
              <td>
                <el-radio-group v-model="queries.tradeTypeCd">
                  <el-radio v-for="item in tradeTypes" :key="item.code" :label="item.code" border>{{item.label}}</el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <th>{{$t('Label.Payment Terms')}}</th>
              <td>
                <el-radio-group v-model="queries.paymentTermsCd">
                  <el-radio v-for="item in PAYMENT_TERMS_VARIABLES" :key="item.code" :label="item.code" border>{{item.label}}</el-radio>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <th>{{$t('Label.Trade Terms')}}</th>
              <td>
                <el-select v-model="queries.tradeTermsCd" placeholder="Select" clearable>
                  <el-option
                    v-for="item in DELIVERY_TERMS_VARIABLES"
                    :key="item.code"
                    :label="item.label"
                    :value="item.code">
                  </el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <th>{{$t('Label.Mode of Transport')}}</th>
              <td>
                <el-radio-group v-model="queries.modeOfTransportCd">
                  <el-radio v-for="item in MODE_OF_TRANSPORT_VARIABLES" :key="item.code" :label="item.code" class="mode_of_transport" border><i :class="[classNames[item.code]]" />{{item.label}}</el-radio>
                </el-radio-group>
              </td>
            </tr>
          </table>
        </div>
      </transition>
    </section>
    <footer>
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item :label="$t('Label.Base Trading Pattern')" prop="tradingPattern" style="margin-bottom: 24px">
          <el-select v-model="form.tradingPattern" placeholder="Select" filterable @change="setTradingName">
            <el-option
              v-for="(pattern, index) in filteredPatterns"
              :key="pattern.tradingPatternId + index"
              :label="pattern.tradingPatternName"
              :value="pattern.tradingPatternId">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('Label.Trading Name')" prop="tradingName" style="margin-bottom: 24px">
          <el-input type="text" v-model="form.tradingName" placeholder="Trading Name" show-word-limit maxlength="100"></el-input>
        </el-form-item>
      </el-form>
      <div style="display: flex;justify-content: flex-end">
        <tw-button id="kc-login" type="primary" size="medium" @click="next">Next</tw-button>
      </div>
    </footer>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { LakeelValidation } from 'lib-lakeel-validation';
import { DELIVERY_TERMS_VARIABLES, MODE_OF_TRANSPORT_VARIABLES, PAYMENT_TERMS_VARIABLES, TRADE_TYPE_VARIABLES, TRADE_TYPE } from 'lib-tw-common';
import TwButton from '@/components/atoms/TwButton';
import { collapseTransition } from '@/utils/nextFrame';

const LAKEEL_SYNERGY_LOGIC_HOSTNAME = process.env.VUE_APP_LAKEEL_SYNERGY_LOGIC_HOSTNAME;

const classNames = {
  0: 'bulk',
  1: 'container',
  2: 'air',
};

export default {
  name: 'TwAddTrading',
  mixins: [collapseTransition],
  props: {
    history: Object,
  },
  components: {
    TwButton
  },
  data() {
    return {
      data: {},
      prevData: false,
      form: {
        tradingPattern: null,
        tradingName: null,
      },
      queries: {
        ownerSectionId: null,
        tradeTypeCd: null,
        paymentTermsCd: null,
        tradeTermsCd: null,
        modeOfTransportCd: null,
      },
      DELIVERY_TERMS_VARIABLES: DELIVERY_TERMS_VARIABLES,
      MODE_OF_TRANSPORT_VARIABLES: MODE_OF_TRANSPORT_VARIABLES,
      PAYMENT_TERMS_VARIABLES: PAYMENT_TERMS_VARIABLES,
      rules: {
        ownerSectionId: [
          {
            lakeelMessageSourceFlg: true,
            validationId: 'tw-isNotEmpty',
            validator: this.onEventValidation,
            trigger: 'change'
          }
        ],
        tradingPattern: [
          {
            lakeelMessageSourceFlg: true,
            validationId: 'tw-isNotEmpty',
            validator: this.onEventValidation, trigger: 'change'
          }
        ],
        tradingName: [
          {
            lakeelMessageSourceFlg: true,
            validationId: 'tw-isMaxLength100',
            validator: this.onEventValidation, trigger: 'change'
          }
        ],
      },
      validationService: new LakeelValidation(LAKEEL_SYNERGY_LOGIC_HOSTNAME),
      validResult: {},
      classNames,
      openForm: false,
    };
  },
  computed: {
    searchCriteriaOwnerSections() {
      return _.get(this.data, 'searchCriteriaOwnerSections') || [];
    },
    baseTradingPatterns() {
      // 三国間ロールを持っていない場合は三国間のパターンは除外する
      return _.reject(_.get(this.data, 'baseTradingPatterns') || [], o => {
        if (this.$store.getters.isIntermediate) {
          return false;
        }
        return o.tradeTypeCd === TRADE_TYPE.OFF_SHORE;
      });
    },
    filteredPatterns() {
      const q = this.queries;
      return _.filter(this.baseTradingPatterns, o => {
        return (!q.ownerSectionId || o.ownerSectionId === q.ownerSectionId)
        && (!q.tradeTypeCd || o.tradeTypeCd === q.tradeTypeCd)
        && (!q.paymentTermsCd || o.paymentTermsCd === q.paymentTermsCd || o.extraPaymentTermsCd === q.paymentTermsCd)
        && (!q.tradeTermsCd || o.tradeTermsCd === q.tradeTermsCd || o.extraTradeTermsCd === q.tradeTermsCd)
        && ((!q.modeOfTransportCd && q.modeOfTransportCd !== 0) || o.modeOfTransportCd === q.modeOfTransportCd || o.extraModeOfTransportCd === q.modeOfTransportCd);
      });
    },
    tradeTypes() {
      if (this.$store.getters.isIntermediate) {
        return TRADE_TYPE_VARIABLES;
      }
      return _.take(TRADE_TYPE_VARIABLES, 2);
    },
  },
  watch: {
    queries:{
      handler(){
        if (!this.prevData) {
          this.form.tradingPattern = null;
        }
      },
      deep: true,
    }
  },
  created() {
    if (this.history) {
      this.prevData = true;
    }
    this.fetch();
  },
  methods: {
    fetch() {
      const params = {
        // bff_td_3 取引新規登録初期表示BFF
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/tradings/{companyId}/manages/drawer',
          path: {
            companyId: this.$store.state.companyId,
          }
        }
      };

      $api.request(params)
      .then(res => {
        this.data = res;
        if (this.prevData) {
          this.form = _.clone(this.history.form);
          this.queries = _.clone(this.history.queries);
          this.$nextTick(() => {
            this.prevData = false;
          });
        }
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    async setTradingName() {
      this.form.tradingName = _.get(_.find(this.filteredPatterns, {tradingPatternId: this.form.tradingPattern}), 'tradingPatternName');
    },
    // 個別バリデーション
    async onEventValidation(rule) {
      const refName = rule.field === 'ownerSectionId' ? 'formOwnerSectionId' : 'form';
      await this.validationService.lakeelValidAll.call(this, {formName: refName, auto: true, field: 'validResult', lslHostName: LAKEEL_SYNERGY_LOGIC_HOSTNAME, relationMsgDisplayType: 'second'}, rule.field);
    },
    // 一括バリデーション
    async validationCheck(ref) {
      await this.validationService.lakeelValidAll.call(this, {formName: ref, auto: true, field: 'validResult', lslHostName: LAKEEL_SYNERGY_LOGIC_HOSTNAME, relationMsgDisplayType: 'second'});
      return !this.validResult.isError;
    },
    async next() {
      const check1 = await this.validationCheck('form');
      const check2 = await this.validationCheck('formOwnerSectionId');
      const isValid = check1 && check2;
      if (isValid) {
        const tradingPatternName = _.get(_.find(this.filteredPatterns, {tradingPatternId: this.form.tradingPattern}), 'tradingPatternName');
        const ownerSectionName = _.get(_.find(this.searchCriteriaOwnerSections, {sectionId: this.queries.ownerSectionId}), 'sectionShortName');
        this.$router.push({
          name: 'NewTrading',
          params: {
            tradingPatternId: this.form.tradingPattern,
            tradingName: this.form.tradingName || tradingPatternName,
            originTradingPatternName: tradingPatternName,
            ownerSectionId: this.queries.ownerSectionId,
            ownerSectionName: ownerSectionName,
            history: {
              form: {
                tradingPattern: this.form.tradingPattern,
                tradingName: this.form.tradingName || tradingPatternName,
              },
              queries: this.queries,
              name: this.$route.name,
            }
          }
        });
      } else {
        this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
      }
    },
    // 検索欄の開閉を切り替えます
    openToggle() {
      this.openForm = !this.openForm;
    },
  }
}
</script>

<style lang="scss" scoped>
  .add_trading {
    header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0 0 24px;
      border-bottom: 1px solid $color_gray_300;

      h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        margin: 0;
      }
    }

    h2, ::v-deep .el-form-item__label {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 12px;
    }

    section .el-select, footer .el-select {
      width: 100%;
    }

    section {
      padding: 24px 0;
      border-bottom: 1px solid $color_gray_300;

      table {
        width: 100%;
        border-collapse: collapse;
        padding: 0;
      }

      th {
        width: 168px;
        padding-right: 8px;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        vertical-align: middle;
        text-align: left;
        padding: 24px 8px 0 0;
      }

      td {
        vertical-align: middle;
        padding: 24px 0 0;

        .el-radio.is-bordered {
          margin-bottom: 0;

          &.mode_of_transport {
            padding-top: 6px;
            padding-bottom: 0;
          }

          i {
            display: inline-block;
            width: 28px;
            height: 28px;
            margin-right: 4px;
            vertical-align: middle;
            background: no-repeat center;
            background-size: 28px auto;

            &.container {
              background-image: url(../../assets/images/icons/icon_container.svg);
            }

            &.bulk{
              background-image: url(../../assets/images/icons/icon_bulk.svg);
            }

            &.air {
              background-image: url(../../assets/images/icons/icon_air.svg);
            }
          }

          &.is-checked i {
            &.container {
              background-image: url(../../assets/images/icons/icon_container_dark.svg);
            }

            &.bulk{
              background-image: url(../../assets/images/icons/icon_bulk_dark.svg);
            }

            &.air {
              background-image: url(../../assets/images/icons/icon_air_dark.svg);
            }
          }
        }

        .el-select {
          width: 160px;
        }
      }
    }

    footer {
      padding: 24px 0;
    }

    img.arrow {
      margin-right: 10px;
    }

    img.arrow.open {
      transform: rotate(90deg);
    }
  }
</style>
