<template>
  <div class="flow_line" v-if="visible" :style="lineStyle" :class="{straight: style.straight, combine: style.isCombine, highlight: prevEntity.isHighlight}">
    <div class="line">
      <svg v-if="style.straight" :width="style.width - 2"  height="4" :viewBox="`0 0 ${style.width} 4`" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path :d="`M2 2H${style.width - 3}`" :stroke="`url(#paint0_linear_7808_66250${flowIndex + '_' + entity.entityTypeCd + (prevEntity.isHighlight ? '_highlight' : '')})`" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
        <linearGradient :id="`paint0_linear_7808_66250${flowIndex + '_' + entity.entityTypeCd + (prevEntity.isHighlight ? '_highlight' : '')}`" y1="3.05625" x2="-2.14801" y2="-5.7063" gradientUnits="userSpaceOnUse">
          <stop :stop-color="getColor.startColor"/>
        <stop offset="1" :stop-color="getColor.stopColor"/>
        </linearGradient>
        </defs>
      </svg>
      <svg v-else :width="style.width - 2" :height="24 + style.height" :viewBox="`0 0 ${style.width - 2} ${24 + style.height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path :d="`M2 2H12.9785C18.1481 2 22.3388 6.19077 22.3388 11.3603V11.3603V27.5108${9 + style.height}V${9 + style.height}C21.3388 ${16 + style.height} 26.4448 ${22 + style.height} 32.7433 ${22 + style.height}H${style.width - 3}`" :stroke="`url(#paint0_linear_7808_66250${flowIndex + '_' + entity.entityTypeCd + (prevEntity.isHighlight ? '_highlight' : '')})`" stroke-width="4" stroke-linecap="round"/>
        <defs>
        <linearGradient :id="`paint0_linear_7808_66250${flowIndex + '_' + entity.entityTypeCd + (prevEntity.isHighlight ? '_highlight' : '')}`" x1="-12.8235" :y1="`${20 + style.height}`" x2="52.5537" :y2="`${17 + style.height}`" gradientUnits="userSpaceOnUse">
        <stop :stop-color="getColor.startColor"/>
        <stop offset="1" :stop-color="getColor.stopColor"/>
        </linearGradient>
        </defs>
      </svg>
    </div>
    <i class="start" :style="computedDotStyle" />
    <i class="end" :style="computedDotStyle" />
  </div>
</template>

<script>
import _ from 'lodash';
import { entityNames } from '@/dictionaries/map.js';
import { color } from '@/assets/styles/styles.js';

const entityColors = {
  CONTRACT: { startColor: '#9B2AD0', stopColor: '#DB8EFF' },
  LC: { startColor: '#29EEE2', stopColor: '#0799A3' },
  TRANSPORTATION_AG: { startColor: '#1C0898', stopColor: '#516BF2' },
  EX_CUSTOMS: { startColor: '#4EB100', stopColor: '#C8E416' },
  TRANSPORTATION: { startColor: '#83F0FF', stopColor: '#0766D6' },
  MARINE_IP: { startColor: '#EA5478', stopColor: '#FF98E2' },
  FINAL_DOCS: { startColor: '#FFD646', stopColor: '#FE7122' },
  COMBINED: { startColor: '#A60606', stopColor: '#FF1313' },
};

export default {
  name: 'TwFlowLine',
  props: {
    entity: Object,
    prevEntity: Object,
    flowIndex: Number,
    flowHeight: Number,
    entitySpanWidth: Number, // エンティティ表示枠の幅
    cardWidth: Number, // エンティティカードの幅
    lineMargin: Number, // ラインのマージン
    partialInfo: Array,
  },
  data() {
    return {
      visible: true,
    }
  },
  computed: {
    entityNames() {
      return _.map(entityNames);
    },
    style() {
      const flow = this.partialInfo[this.flowIndex];
      let prevFlowIndex;
      if (_.find(flow.entityStatus, {entityId: this.prevEntity.prevEntityId})) {
        // 同フロー内に先行エンティティIDがいる場合
        prevFlowIndex = this.flowIndex;
      } else {
        if (this.prevEntity.prevUuid) {
          prevFlowIndex = _.findIndex(this.partialInfo, o => {
            return this.prevEntity.prevUuid === o.uuid;
          });
        } else {
          prevFlowIndex = _.findIndex(this.partialInfo, o => {
            return this.prevEntity.prevTradingFlowId === o.tradingFlowId;
          });
        }
      }
      // フローがレスポンスに存在しないときは線は非表示
      if (prevFlowIndex < 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.visible = false;
      }
      const prevEntityTypeCd = this.prevEntity.prevEntityTypeCd || _.get(_.find(this.partialInfo[prevFlowIndex].entityStatus, {entityId: this.prevEntity.prevEntityId}), 'entityTypeCd');
      const entityId = _.get(_.find(this.entityNames, {code: prevEntityTypeCd}), 'entityId');
      const currentIndex = _.findIndex(this.entityNames, {code: this.entity.entityTypeCd});
      const prevIndex = _.findIndex(this.entityNames, {code: prevEntityTypeCd});
      const width = (currentIndex - prevIndex) * this.entitySpanWidth - this.cardWidth - this.lineMargin;
      const height = (this.flowIndex - prevFlowIndex) * (this.flowHeight + 1);
      return {
        width: width,
        height: Math.abs(height) - 22,
        entityId: entityId,
        straight: this.flowIndex === prevFlowIndex,
        transform: height > 0 ? 'none' : `translateY(${Math.abs(height) - 2}px) scaleX(-1)`,
        isCombine: height < 0,
      };
    },
    lineStyle() {
      return {
        width: this.style.width ? this.style.width + 'px' : undefined,
        height: this.style.straight ? '4px' : (this.style.height + 24 + 'px'),
        right: this.entitySpanWidth + this.lineMargin / 2 - 1 + 'px',
      }
    },
    getColor() {
      if (this.prevEntity.isHighlight) {
        // 混載プレビュー時はハイライト色を返す
        return entityColors.COMBINED;
      }

      return entityColors[this.style.entityId] || {};
    },
    computedDotStyle() {
      if (this.prevEntity.isHighlight) {
        return { background: color.gradation_COMBINED };
      }
      return {
        background: color[`gradation_${this.style.entityId}`],
      };
    },
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
  .flow_line {
    position: absolute;
    height: 4px;
    bottom: calc(50% - 4px);
    right: 173px;
    width: 48px;

    i {
      display: block;
      position: absolute;
      top: -4px;
      width: 12px;
      height: 12px;
      border-radius: 100%;

      &.start {
        left: -2px;
      }

      &.end {
        top: auto;
        bottom: -4px;
        right: -2px;
      }
    }

    &.combine:not(.straight) {
      top: calc(50%);
      bottom: auto;
      i {
        &.end {
          top: -4px;
          bottom: auto;
        }

        &.start {
          top: auto;
          bottom: -4px;
        }
      }

      svg {
        transform: rotate(180deg) scaleX(-1);
      }

    }

    &.straight i {
      &.start, &.end {
        top: -4px;
        bottom: auto;
      }
    }

    &.highlight .line {
      opacity: 0.8;
      z-index: 1;
    }

    &.highlight i {
      z-index: 1;
    }
  }

  .line {
    position: absolute;

    left: 0;
    display: flex;
    align-items: center;
    opacity: 0.5;
  }
</style>
