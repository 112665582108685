/**
 * -------- プロセス登録、プロセス詳細、取引詳細でのタブ表示用の辞書配列 ---------
 * Goodsタブの、GoodsLineを含むテーブル名は各画面にて取り込みを行い、それ以外のテーブルのグループ項目をgoodsタブ辞書からフィルタリング
 * Undisclosedタブは、〇〇個別テーブルをそのまま取り込むだけで済むため、辞書化していない（Agile2現在）
 * Players、Logisticsタブは、辞書からフィルタリング
 * Informationタブは、他のタブに属さないものを全て表示（辞書に未定義ものもinformationタブに入る）
 */

/**
 * NOTE: 1. 新規に情報群が追加された場合は、変数名を↓↓の辞書配列に追加する必要があります（プロセス定義シートP〜T列を参照）未定義のものは全てInformationタブ表示になります
 * NOTE: 2. プロセス定義シートからコピペで辞書配列を生成できるようにする（excelToJsonを作る）
 */

import { PROCESS_TYPE } from 'lib-tw-common';

// eslint-disable-next-line import/prefer-default-export
export const processTabType = {
  // Goodsタブ（GoodsLineを含むテーブル名は各画面で個別にフィルタリングする ※テーブルの順序入替が必要なため） ======================================================
  // GoodsLineを含むテーブル以外のテーブルから項目を引っ張ってくる必要がある場合は、以下に追加する（〇〇AmountGrpなど）
  // プロセスごとにGoodsLindeに含める必要があるグループは個別に定義する
  goods: {
    allGroup: [
      // 契約（linkageContract） ---------------
      'contractAmountGrp',
      'expensesGrp',
      // L/C（linkageLc） ---------------
      'lcAmountGrp',
      // 輸出通関（linkageExportCustoms） ---------------
      'exCustomsAmountGrp',
      // 輸送（linkageTransportation） ---------------
      'transportationGoodsGrp',
      'transportationQuantityGrp',
      'estimatedWeightMeasurementGrp',
      'weightMeasurementGrp',
      'weightMeasurementFwdGrp',
      'packingTermsGrp', // NOTE: Ph4よりInformationタブから移動
      // 決済書類（linkageFinalDocs） ---------------
      'finalQuantityAmountGrp',
      // 輸入通関（linkageImportCustoms） ---------------
      'imCustomsAmountGrp',
    ],
    // PO送付プロセス
    [PROCESS_TYPE.COPO]: {
      tableName: 'linkageContract',
      groups: [
        // 契約（linkageContract） ---------------
        'contractAmountGrp',
        'expensesGrp',
      ],
    },
    // 契約締結プロセス
    [PROCESS_TYPE.COCOS]: {
      tableName: 'linkageContract',
      groups: [
        // 契約（linkageContract） ---------------
        'contractAmountGrp',
        'expensesGrp',
      ],
    },
    // L/C保管プロセス
    [PROCESS_TYPE.LCACCEPT]: {
      tableName: 'linkageLc',
      groups:[
        // LC（linkageLc） ---------------
        'lcAmountGrp',
      ],
    },
    // 輸出船積依頼プロセス
    [PROCESS_TYPE.ECREQ]: {
      tableName: 'linkageExportCustoms',
      groups:[
        // 輸出通関（linkageExportCustoms） ---------------
        'exCustomsAmountGrp',
      ],
    },
    // 輸出許可書保管プロセス
    [PROCESS_TYPE.ECPERMIT]: {
      tableName: 'linkageExportCustoms',
      groups:[
        // 輸出通関（linkageExportCustoms） ---------------
        'exCustomsAmountGrp',
      ],
    },
    // 船積書類作成プロセス
    [PROCESS_TYPE.FDCRTDOC]: {
      tableName: 'linkageFinalDocs',
      groups: [
        // 決済書類（linkageFinalDocs） ---------------
        'finalQuantityAmountGrp',
      ],
    },
    // C/O申請プロセス
    [PROCESS_TYPE.FDCOR]: {
      tableName: 'linkageFinalDocs',
      groups: [
        // 決済書類（linkageFinalDocs） ---------------
        'finalQuantityAmountGrp',
      ],
    },
    // C/O発行プロセス
    [PROCESS_TYPE.FDCOI]: {
      tableName: 'linkageFinalDocs',
      groups: [
        // 決済書類（linkageFinalDocs） ---------------
        'finalQuantityAmountGrp',
      ],
    },
    // 船積書類送付プロセス
    [PROCESS_TYPE.FDDOC]: {
      tableName: 'linkageFinalDocs',
      groups: [
        // 決済書類（linkageFinalDocs） ---------------
        'finalQuantityAmountGrp',
      ],
    },
    // 輸入荷捌依頼プロセス
    [PROCESS_TYPE.ICREQ]: {
      tableName: 'linkageImportCustoms',
      groups: [
        // 輸入通関（linkageImportCustoms） ---------------
        'imCustomsAmountGrp',
      ],
    },
    // 輸入許可書保管プロセス
    [PROCESS_TYPE.ICPERMIT]: {
      tableName: 'linkageImportCustoms',
      groups: [
        // 輸入通関（linkageImportCustoms） ---------------
        'imCustomsAmountGrp',
      ],
    },
  },
  // Playersタブ =====================================================================================
  players: [
    // 契約（linkageContract） ---------------
    'sellerGrp',
    'buyerGrp',
    'makerGrp',
    'vendorGrp',
    'endUserGrp',
    'distributorGrp',
    // L/C（linkageLc） ---------------
    'applicantGrp',
    'lcOpenerGrp',
    'lcBeneficiaryGrp',
    'lcApplicantBankGrp',
    'lcIssuingBankGrp',
    'lcNonBankIssuerGrp',
    'lcConfirmingBankGrp',
    'lcReceivingBankGrp',
    'lcAdvisingBankGrp',
    'lcAdviseThroughBankGrp',
    'lcReimbursingBankGrp',
    'lcNegotiationBankGrp',
    // 輸出通関（linkageExportCustoms） ---------------
    'customsAgentGrp',
    'forwarderDocumentGrp',
    // 輸送（linkageTransportation） ---------------
    'surveyorGrp',
    'fwdCntGrp',
    'fwdBkgGrp',
    'forwarderNvoccGrp',
    'charteringBrokerGrp',
    'carrierGrp',
    'airfreightGrp',
    'fwdImCntGrp',
    'fwdImBkrGrp',
    'impForwarderNvoccGrp',
    'shipperGrp',
    'consigneeGrp',
    'notify1Grp',
    'notify2Grp',
    'destinationGrp',
    // 海上保険（linkageMarineInsurance） ---------------
    'forwarderIpGrp',
    // 輸入通関（linkageImportCustoms） ---------------
    'customsAgentGrp',
    'surveyorGrp',
  ],
  // Logisticsタブ =====================================================================================
  logistics: [
    // 契約（linkageContract） ---------------
    'requestedCargoReadyTimeGrp',
    // 輸出通関（linkageExportCustoms） ---------------
    // 輸送（linkageTransportation） ---------------
    'cargoScheduleGrp',
    'cargoScheduleContainerFclGrp',
    'cargoScheduleContainerLclGrp',
    'cargoScheduleBulkGrp',
    'cargoScheduleAirGrp',
    'bookingInformationGrp',
    'bookingInformationAirGrp',
    'bookingReferenceGrp',
    'transferTermsGrp', // NOTE: Ph2改善要望でTransfer Termはタブ外に表示する
    'containerInfoGrp',
    'blRequestGrp',
    'blGrp',
    'bulkGrp',
    'containerGrp',
    'airGrp',
    'logisticsLoadingGrp',
    'logisticsViaGrp',
    'logisticsDischargingGrp',
    'logisticsDeliveryGrp',
    // 輸入通関（linkageImportCustoms） ---------------
    'devanningGrp',
  ],
  // Informationタブ goods、players、logisticsに属さないものを全て表示するため定義しない =====================================================================================
  // information: [],
  // Undisclosedタブ Agile2時点では未使用 =====================================================================================
  // undisclosed: [],
}
