<template>
  <div ref="table" class="tw_process_list" :class="{open: fixedOpen || open}">
    <tr class="inner" @click="toggle">
      <td class="arrow">
        <img src="@/assets/images/icons/accordion_arrow.svg">
      </td>
      <td class="table_name">
        L/C
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <transition
          name="content"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
          @after-leave="afterLeave"
        >
          <div v-show="fixedOpen || open" class="collapse">
            <template>
              <div class="ip_dn">
                <!-- ドロワー -->
                <template v-if="isEditMode && localValue.linkageLc.lcpools && localValue.linkageLc.lcpools.length">
                  <!-- 表見出し -->
                  <table class="goods_table fixed_header" :style="{width: scroller1Width, minWidth: scroller1Width}" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th v-for="column in lcPreviewTableSchema" :key="column.label" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                          <div style="padding: 0 5px">{{column.label}}</div>
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <!-- 表項目 -->
                  <div class="table_scroller" ref="scroller1">
                    <table class="goods_table" style="margin-top: 0" cellspacing="0" cellpadding="0">
                      <thead>
                        <tr>
                          <th v-for="(column, index) in lcPreviewTableSchema" :key="index" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                            <div style="padding: 0 5px">{{column.label}}</div>
                          </th>
                        </tr>
                      </thead>
                      <template v-for="(row, index) in localValue.linkageLc.lcpools">
                        <!-- 表項目 -->
                        <TwProcessGroupListPreviewLcTable :row="row" :key="index" :selected="index === selectLcIndex" @click="selectLc(row, index)" :schemas="lcPreviewTableSchema" />
                      </template>
                    </table>
                  </div>
                </template>

                <!-- 閲覧用 -->
                <template v-if="!isEditMode && s.res.linkageLc.lcpools && s.res.linkageLc.lcpools.length">
                  <!-- 表見出し -->
                  <table class="goods_table fixed_header" :style="{width: scroller1Width, minWidth: scroller1Width}" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th v-for="column in lcPreviewTableSchema" :key="column.label" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                          <div style="padding: 0 5px">{{column.label}}</div>
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <div class="table_scroller" ref="scroller1" style="min-width: 930px">
                    <table class="goods_table" style="margin-top: 0" cellspacing="0" cellpadding="0">
                      <thead>
                        <tr>
                          <th v-for="(column, index) in lcPreviewTableSchema" :key="index" style="padding: 4px 0" :style="{width: column.width + 'px' || 'auto'}">
                            <div style="padding: 0 5px">{{column.label}}</div>
                          </th>
                        </tr>
                      </thead>
                      <template v-for="(row, index) in s.res.linkageLc.lcpools">
                        <!-- 表項目 -->
                        <TwProcessGroupListPreviewLcTable :row="row" :key="index" :selected="index === selectLcIndex" @click="selectLc(row, index)" :schemas="lcPreviewTableSchema"/>
                      </template>
                    </table>
                  </div>
                </template>
                <!-- 表以外の項目 -->
                <TwProcessListPreviewLc v-if="selectLcIndex || selectLcIndex === 0" :isEditMode="isEditMode" :isUpload="isUpload" :isProcess="isProcess" :selectIndex="selectLcIndex" :table="table" />
              </div>
            </template>
          </div>
        </transition>
      </td>
    </tr>
  </div>
</template>

<script>
import { collapseTransition } from '@/utils/nextFrame';
import lcPreviewTableSchema from '@/dictionaries/lcPreviewTableSchema.json';
import TwProcessGroupListPreviewLcTable from '@/components/molecules/TwProcessGroupListPreviewLcTable';
import TwProcessListPreviewLc from '@/components/molecules/TwProcessListPreviewLc';

export default {
  name: 'TwLcPoolProcessList',
  inject: ['s'],
  provide() {
    return {
      tab: this.tab,
    }
  },
  props: {
    table: Object,
    isEditMode: Boolean,
    value: Object,
    fixedOpen: {
      // タブ外の必須項目を含む情報群等で、常にopen状態にしておきたい場合true
      type: Boolean,
      default: false,
    },
    initialOpen: {
      type: Boolean,
      default: false,
    },
    initSelectLc: Boolean,
    isUpload: Boolean,
    isProcess: Boolean,
    tab: Number,
  },
  components: {
    TwProcessGroupListPreviewLcTable,
    TwProcessListPreviewLc,
  },
  mixins: [collapseTransition],
  data() {
    return {
      open: this.isEditMode || this.initialOpen,
      lcPreviewTableSchema: lcPreviewTableSchema,
      selectLcIndex: null,
      scroller1Width: '100%',
      // scroller2Width: '100%',
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    drawerShow() {
      return this.$store.state.drawerShow;
    },
  },
  watch: {
    drawerShow(val) {
      if (!val) {
        this.resetSelect();
      }
    },
  },
  created() {
    this.setWidth();
    this.$parent.$on('openAll', this.openAll);
    this.$parent.$on('closeAll', this.closeAll);
    if (this.initSelectLc) this.selectLcIndex = 0;
  },
  beforeDestroy() {
    this.$parent.$off('openAll', this.openAll);
    this.$parent.$off('closeAll', this.closeAll);
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    // 選択時
    selectLc(row, index) {
      if (this.selectLcIndex === index) this.selectLcIndex = null;
      else this.selectLcIndex = index;
    },
    resetSelect() {
      this.selectLcIndex = null;
    },
    openAll() {
      this.open = true;
      this.$emit('openAll');
    },
    closeAll() {
      this.open = false;
      this.$emit('closeAll');
    },
    setWidth() {
      this.scroller1Width = `calc(100% - ${this.$store.state.scrollbarWidth}px)`;
    },
  }
};
</script>

<style lang="scss" scoped>
  .tw_process_list {
    display: table-row-group;
    position: relative;
    background: $color_white;
    @include card_shadow;

    &.has_error {
      background: rgba($color_warning, 0.03);

      > tr.inner {
        background: rgba($color_warning, 0.1);
      }
    }

    &:first-child > tr > td {
      border: none!important;
    }

    .inner {
      display: table-row;
      cursor: pointer;

      td.arrow {
        width: 20px;
        vertical-align: middle;
        padding: 10px 0 10px 8px;
        border-top: 1px solid $color_gray_300;

        img {
          display: block;
          width: 12px;
          height: 12px;
          transition: transform .3s;
        }
      }
    }

    .table_name {
      // width: 206px;
      vertical-align: middle;
      padding: 10px 8px 10px 16px;
      font-size: 16px;
      line-height: 24px;
      color: $color_gray_800;
      white-space: nowrap;
      border-top: 1px solid $color_gray_300;

      &.full {
        width: 100%;
        padding-bottom: 0;
      }
    }

    .collapse {
      padding: 0 22px 10px;
    }

    &.open {
      .inner td.arrow img {
        transform: rotate(90deg);
      }

      .inner {
        @include list_shadow;
      }
    }
  }

  .content-enter-active,
  .content-leave-active {
    transition: height .3s;
  }

  .table_scroller {
    max-width: 100%;
    max-height: 275px;
    overflow: hidden;
    overflow-y: scroll;

    &.edit {
      max-width: 1065px;
      max-height: 370px;
    }

    table.goods_table thead {
      display: none;
    }
  }

  table.goods_table {
    table-layout: fixed;
    min-width: 100%;
    // border: 1px solid #BFBFCD;
    border-collapse: collapse;
    background: #FFFFFF;
    margin-top: 24px;

    td.goods_line_table_inner {
      position: relative;

      img.close_times {
        position: absolute;
        right: 4px;
        top: 50%;
        margin-top: calc(13px - 10px);
        width: 20px;
        height: 20px;
        cursor: pointer;
        opacity: 0;
      }

      table  {
        table-layout: fixed;
        min-width: 100%;
        border: 0;
        border-collapse: collapse;

        th {
          border-top: 0!important;
          padding: 4px 5px;
          white-space: normal;
        }

        td {
          border-top: 0!important;
          border-bottom: 0!important;
          padding: 0;
        }

        tr th:first-child, tr td:first-child {
          border-left: 0!important;
        }

        tr th:last-child, tr td:last-child {
          border-right: 0!important;
        }
      }
    }

    th {
      text-align: center;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      word-break: break-word;
      padding: 4px 5px;
      border-left: 1px solid $color_gray_300;
      border-right: 1px solid $color_gray_300;
      background: $color_dark_blue;
      color: white;
      // font-weight: bold;
    }

    td {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
      padding: 0;
      border: 1px solid $color_gray_300!important;
    }

    .el-form-item {
      width: 100%;
      padding-right: 0;
      margin: 0;

      ::v-deep {
        label.el-form-item__label {
          display: none;
        }

        .el-input, .el-select, .el-input-number, .el-input-number.amount {
          width: 100%!important;
        }

        .el-input-number .el-input__inner {
          text-align: right;
        }

        .el-input__inner {
          font-size: 12px;
          width: 100%;
          background: transparent;
          box-shadow: none;
          border-radius: 0;
          padding: 0 5px;

          &:not(:focus) {
            border: 0;
          }
        }

        .el-select {
          .el-input__inner {
            padding-right: 20px;
            overflow: hidden;
            white-space: nowrap;
          }
          .el-input__suffix {
            right: 0;
          }
        }

        .el-form-item__error {
          white-space: normal;
          font-size: 10px;
          left: 5px;
          margin-bottom: 5px;
        }

        .warning_message {
          font-size: 10px;
          background-position: 5px 2px;
          padding-left: 20px;
        }
      }
    }
  }
</style>
