<template>
  <div v-if="selectLc && Object.keys(selectLc).length" class="tw_process_list_preview_lc">
    <!-- エラー表示 -->
    <div class="errors" v-if="isErrors && errMessage">
      <div class="tw_process_group_list_item">
        <div class="value">{{errMessage}}</div>
      </div>
    </div>
    <!-- エラー詳細 オブジェクト -->
    <div class="errors meta" v-if="isErrors && errMetaObj && Object.keys(errMetaObj).length">
      <div v-for="(item, index) in errMetaObj" :key="index" class="tw_process_group_list_item">
        <div class="value">{{item}}</div>
      </div>
    </div>
    <!-- エラー詳細 配列 -->
    <div class="errors meta" v-if="isErrors && errMetaArr && errMetaArr.length">
      <template v-for="(array, index) in errMetaArr">
        <div :key="index" class="tw_process_group_list_item">
          <div v-for="(item, i) in array" :key="i" class="value">{{item}}</div>
        </div>
      </template>
    </div>
    <!-- Swift項目 700 - 799 -->
    <template v-if="!isUpload && swiftItems && swiftItems.length">
      <TwProcessGroupListPreviewLcSwift
        :initOpen="true"
        :swiftItems="swiftItems"
        style="margin-top: 8px"
      />
    </template>
    <!-- カバーレター項目 -->
    <template v-if="cltItems && Object.keys(cltItems).length" class="tw_process_group_list twoColumn">
      <TwProcessGroupListPreviewLc
        v-for="group in cltItems"
        :key="`${group.variableName}${selectIndex}`"
        :group="group"
        :isEditMode="isEditMode"
        :selectIndex="selectIndex"
        :initOpen="true"
        :isUpload="isUpload"
        :isProcess="isProcess"
        style="margin-top: 8px"
      />
    </template>
    <!-- swift項目以外 -->
    <template v-if="lcItems && lcItems.length">
      <TwProcessGroupListPreviewLc
        v-for="group in lcItems"
        :key="`${group.variableName}${selectIndex}`"
        :group="group"
        :isEditMode="isEditMode"
        :selectIndex="selectIndex"
        :initOpen="true"
        :isUpload="isUpload"
        :isProcess="isProcess"
      />
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import lcPoolDetailSchema from '@/dictionaries/lcPool/lcPoolDetailSchema.json';
import { swiftExcludeItems, swiftExcludeItems799 } from '@/dictionaries/lcPool/lcPoolSwiftSchema.js';
import lcPoolCltSchema from '@/dictionaries/lcPool/lcPoolCltSchema.json';
import TwProcessGroupListPreviewLc from '@/components/molecules/TwProcessGroupListPreviewLc';
import TwProcessGroupListPreviewLcSwift from '@/components/molecules/TwProcessGroupListPreviewLcSwift';

export default {
  name: 'TwProcessListPreviewLc',
  components: {
    TwProcessGroupListPreviewLc,
    TwProcessGroupListPreviewLcSwift,
  },
  props: {
    selectIndex: Number,
    isEditMode: Boolean,
    isUpload: Boolean,
    isProcess: Boolean,
  },
  inject: ['s'],
  data() {
    return {
      open: true,
    };
  },
  computed: {
    selectLc() {
      const table = this.isEditMode ? this.s.cloneItems.linkageLc : this.s.res.linkageLc;
      if (table.lcpools && table.lcpools.length) {
        return table.lcpools[this.selectIndex] || {};
      } else return {};
    },
    cltItems() {
      const lcdata = _.get(this.selectLc, 'lcdata');
      if (!lcdata) return [];
      const messageType = _.get(lcdata, 'messageType');
      if (messageType === 'CLT') return lcPoolCltSchema.groups;
      else return [];
    },
    swiftItems() {
      const lcdata = _.get(this.selectLc, 'lcdata');
      if (!lcdata) return [];
      const messageType = _.get(lcdata, 'messageType');
      if (messageType === 'CLT') return [];
      else {
        let selectLcItems = this.s.cloneDeep(lcdata);
        return this.createSwiftItems(selectLcItems, messageType);
      }
    },
    lcItems() {
      if (this.isProcess) {
        return _.filter(lcPoolDetailSchema.groups, (group) => {
          return group.variableName === 'overview';
        });
      } else return lcPoolDetailSchema.groups;
    },
    isErrors() {
      const errors = _.get(this.selectLc, 'errors', {});
      return errors && Object.keys(errors).length > 0;
    },
    errMessage() {
      const errors = _.get(this.selectLc, 'errors', {});
      return _.get(errors, 'message', '');
    },
    errMetaObj() {
      const errors = _.get(this.selectLc, 'errors.meta', {});
      if (this.isObject(errors)) return errors;
      else return {};
    },
    errMetaArr() {
      const errors = _.get(this.selectLc, 'errors.meta', []);
      if (Array.isArray(errors)) return errors;
      else return [];
    },
  },
  created() {
    this.$parent.$on('openAll', this.openAll);
    this.$parent.$on('closeAll', this.closeAll);
  },
  beforeDestroy() {
    this.$parent.$off('openAll', this.openAll);
    this.$parent.$off('closeAll', this.closeAll);
  },
  methods: {
    getGroup(children) {
      return _.reject(children, o => {
        return this.omit.includes(o.key);
      });
    },
    openAll() {
      this.open = true;
      this.$emit('openAll');
    },
    closeAll() {
      this.open = false;
      this.$emit('closeAll');
    },
    // swift項目を作成
    createSwiftItems(selectLcItems, messageType) {
      let omitItems =  _.omitBy(selectLcItems, (value, key) => {
        // swift以外の項目を除外
        if (messageType === '799') {
          return swiftExcludeItems.includes(key) || swiftExcludeItems799.includes(key);
        } else {
          return swiftExcludeItems.includes(key);
        }
      });
      if (omitItems && Object.keys(omitItems).length) {
        // swift項目を除外したオブジェクトを配列に変換
        omitItems = Object.entries(omitItems).map(([key, value]) => ({key, value}));
        omitItems = _.chunk(omitItems, 3); // TagNo, TagName, TagValueの3つずつになるよう分割
        // TagNo, TagName, TagValueの順にソート
        omitItems = omitItems.map((items) => {
          return _.sortBy(items, (item) => {
            const key = item.key;
            if (key.endsWith('TagNo')) return 1;
            else if (key.endsWith('TagName')) return 2;
            else return 3;
          });
        });
        return omitItems;
      } else return [];
    },
    // エラー情報meta詳細のオブジェクト判定
    isObject(value) {
      return value !== null && typeof value === 'object' && Array.isArray(value) === false;
    },
  }
};
</script>

<style lang="scss" scoped>
.tw_process_list_preview_lc {
  display: flex;
  flex-wrap: wrap;
  // NOTE: 「\n」改行対応
  white-space: pre-wrap;
}
.tw_process_group_list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.tw_process_group_list_item {
  width: 50%;
  min-width: 300px;
  display: flex;
  padding: 0 0 16px 16px;
  &.full {
    width: 100%;
  }
  .label {
    width: 118px;
    font-size: 12px;
    line-height: 18px;
    color: #9191A4;
    margin-right: 8px;
    vertical-align: top;
    padding-top: 2px;
  }
  .value {
    font-size: 14px;
    line-height: 20px;
    color: #3E3A39;
    vertical-align: top;
    flex: 1;
  }
}
.errors {
  padding: 0 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $color_gray_300;
  margin-bottom: 16px;
  .tw_process_group_list_item {
    width: 100%;
    margin-bottom: 16px;
    padding: 0;
    .value {
      color: $color_warning;
    }
    &:first-child {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }
  &.meta {
    .tw_process_group_list_item {
      margin: 8px 0 4px;
      &:first-child {
        margin-top: 8px;
      }
      &:last-child {
        margin-bottom: 24px;
      }
      .value {
        margin-right: 16px;
      }
    }
  }
}
.table_name {
  width: 100%;
  vertical-align: middle;
  padding-top: 10px;
  font-size: 16px;
  line-height: 24px;
  color: $color_gray_800;
  white-space: nowrap;
  border-top: 1px solid $color_gray_300;
  margin-bottom: 32px;
}
</style>
