/**
 * L/Cプール詳細画面 SWIFT項目表示用辞書
 */

/**
 * NOTE:
 * messageType: 700 - 799までのLCプール詳細では、
 * swiftExcludeItemsに含まれない項目をSWIFT項目として判定して表示する
 * 参照資料：(確認用)テーブル定義書（LC）.v5.31.xlsx
 */
export const swiftExcludeItems = [
  // 700 - 799 共通項目 -------------------------
  'messageType',
  'ourReferenceNo',
  'bankBranchNo',
  'officialNameOfBankBranch',
  'beneficiaryNo',
  'companyNameOfBeneficiary',
  'bicCode',
  'sendersInstitution',
  'branchOfSender',
  'countryCityOfSender',
  'ourReferenceNoLcOriginal',
  'sequenceOfAmendment',
  'senderCompanyId',
  'sequenceNumberOfTotal',
  'allNotificationSerialNumbers',
  'swiftVersion',
  'swiftReceiveDate',
  'swiftReceiveDateBank',
  'documentId',
  'ownerCompanyId',
  'ownerSectionId',
  'createCompanyId',
  'createUserId',
  'createUserName',
  'createUserIcon',
  'createSectionId',
  'createDate',
  'updateUserId',
  'updateUserName',
  'updateUserIcon',
  'trx',
  'linkingFlag',
  'entityId',
  'processId',
  'processSeq',
  'processTrx',
  'tradingId',
  'tradingFlowId',
  'tradingName',
  'tradingManageName',
  'processName',
  'fromCompanyIcon',
  'fromCompanyShortName',
  'fromCompanyName',
  'fromSectionName',
  'toCompanyIcon',
  'toCompanyShortName',
  'toCompanyName',
  'toSectionName',
  // 707, 799のみ持つSWIFTではない項目 -------------------------
  'beneficiaryConsentFlag',
  // CLTにある項目 念のため除外 -------------------------
  'ownerSectionName',
  'createCompanyName',
  'createSectionName',
]

/**
 * NOTE:
 * messageType: 799までのの場合は以下の項目も除外する
 */
export const swiftExcludeItems799 = [
  // 799のみ持つSWIFTではない項目 -------------------------
  'contentType',
  'documentaryCreditNumber',
  'currencyCodeAmount',
  'increaseOfLcAmount',
  'decreaseOfLcAmount',
  'tolerance',
  'dateAndPlaceOfExpiry',
  'latestDateOfShipment',
  'changedDateAndPlaceOfExpiry',
  'changedLatestDateOfShipment',
  'preliminaryBody1',
  'preliminaryBody2',
  'preliminaryBody3',
  'preliminaryBody4',
  'preliminaryBody5',
]
