var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card_container",style:({
      background: _vm.active ? 'none' : _vm.getColor,
      transitionDelay: _vm.active ? '0s' : (((_vm.processes.length + 1) * 0.2) + "s"),
      zIndex: _vm.active ? 2 : 1,
    })},[_c('div',{staticClass:"first_display",on:{"click":_vm.handleActive}},[_c('div',{staticClass:"head"},[_c('tw-entity-icon',{attrs:{"entityName":_vm.entityName,"size":"medium","color":"white"}}),_c('tw-status-label',{attrs:{"statusName":_vm.getStatusLabel,"size":"small"}})],1),_c('div',{staticClass:"contract_no"},[_c('div',{staticStyle:{"flex":"1","width":"100px"}},[_c('label',[_vm._v(_vm._s(_vm.getNumberLabel))]),_c('tw-overflow-tooltip',{attrs:{"content":_vm.item.no}})],1),_c('div',{staticStyle:{"flex-shrink":"0"}},[_c('tw-subtask-icon',{attrs:{"number":_vm.item.processStatus.length}})],1)])]),_c('div',{staticClass:"first",style:({
      transform: _vm.active
        ? "rotate3d(1, 0, 0, -180deg)"
        : "rotate3d(1, 0, 0, 0deg)",
      transitionDelay: _vm.active ? '0s' : ((_vm.processes.length * 0.2) + "s")
    })},[(_vm.processes.length)?_c('div',{staticClass:"first_top contract_no",style:({
        boxShadow: _vm.active ? '0px -4px 8px rgba(170, 170, 204, 0.5)' : 'none',
        transitionDelay: _vm.active ?  '0s' : (((_vm.processes.length + 1) * 0.2) + "s")
      }),on:{"click":_vm.handleActive}},[_c('div',{staticStyle:{"flex":"1","width":"100px"}},[_c('label',[_vm._v(_vm._s(_vm.getNumberLabel))]),_c('tw-overflow-tooltip',{attrs:{"content":_vm.item.no}})],1),_c('div',{staticStyle:{"flex-shrink":"0"}},[_c('tw-subtask-icon',{attrs:{"number":_vm.processes.length}})],1)]):_vm._e(),(_vm.processes.length)?_c('TwFlowCardProcess',{attrs:{"active":_vm.active,"index":0,"processes":_vm.processes,"isDraft":_vm.isDraft},on:{"show-detail":_vm.showDetail}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }